import React, { Component } from 'react';
import Icon from '../../../assets/images/icons/world.svg';
import '../../../assets/css/custom.css';
import { serverResponse } from '../../../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../../settings/gaConfig';
import { isMobile } from 'react-device-detect';

export default class ExternalLinkMarker extends Component {
    componentDidMount() {
        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id, {
                gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
            })
        }
    }

    handleClick = () => {
        switch (this.props.markerInfo.data.link_type) {
            case "url":
                window.open(this.props.markerInfo.data.link, '_blank');
                break;
            case "email":
                window.open("mailto:" + this.props.markerInfo.data.link, '_blank');
                break;
            case "telephone":
                window.open("tel:" + this.props.markerInfo.data.link);
                break;
        }
    }

    render() {
        return (
            <div className={"marker "  +  serverResponse.config.iconAnimation} type="world" style={{ display: this.props.info.hidden_price ? "none" : "" }}>
                <img id={"world-" + this.props.info.item_id} src={Icon} className={serverResponse.config.largeIcon ? "icon-marker-large" : "icon-marker"} style={{ display: (isMobile ? "none" : !this.props.markerInfo.data.show_icon ? "none" : "") }} alt={this.props.markerInfo.title} onClick={() => {
                    if (serverResponse.config.ga_active) {
                        ReactGA.event({
                            category: serverResponse.config.release_id,
                            action: gaEventLabel.open_product_link,
                            label: String(this.props.info.item_id)
                        });
                    }
                    this.handleClick();
                }} />
                {isMobile &&
                    <img src={Icon} className="icon-marker" alt={this.props.markerInfo.title} onClick={() => {
                        console.log(this.props)
                        this.props.navMarkers(this.props.info.pam ? "world" : "plus");
                    }} />
                }
            </div>
        );
    }
}