import React, { Component } from 'react';

import { Scrollbars } from "react-custom-scrollbars";
import {
    Popover,
    PopoverHeader,
    PopoverBody,
    Input,
    Tooltip,
    Button
} from "reactstrap";

import { connect } from 'react-redux';
import actions from '../../redux/groceryList/action';
import GroceryProduct from './GroceryProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faTimes,
    faPlusCircle,
    faMinusCircle
} from '@fortawesome/free-solid-svg-icons';
import ShareGroceryList from '../share/ShareGroceryList';
import AddProd from '../../assets/images/icons/add_prod.svg';
import ClearChart from '../../assets/images/icons/clear_chart.png';
import { strings } from '../../util/data/strings';
import {
    faFileDownload,
    faStore
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { serverResponse } from '../../util/fakeServer';
import { isMobile } from 'react-device-detect';
import { forEach } from 'lodash';

const img_custom = require("assets/images/prodotto.png");

class GroceryList extends Component {

    state = {
        newProductTitle: "",
        newProductPrice: "",
        newProductQuantity: 1,
        saveTooltipOpen: false,
        deleteAllTooltipOpen: false,
    }

    handleChangeTitle = (e) => {
        this.setState({ newProductTitle: e.target.value });
    }

    handleChangePrice = (e) => {
        this.setState({ newProductPrice: e.target.value });
    }

    handleKeyUp = (event) => {
        var keycode = event.which;
        if (!(event.shiftKey === false && (keycode === 46 || keycode === 8 || keycode === 37 || keycode === 39 || (keycode >= 48 && keycode <= 57)))) {
            event.preventDefault();
        }
    }

    handleChangeQuantity = (type) => {
        let newQuantity;
        if (type === 1) { // increment
            newQuantity = this.state.newProductQuantity + 1;
        } else { //decrement
            if (this.state.newProductQuantity > 1) {
                newQuantity = this.state.newProductQuantity - 1;
            } else {
                newQuantity = this.state.newProductQuantity;
            }
        }
        this.setState({ newProductQuantity: newQuantity });
    }

    saveNewProduct = () => {
        let {
            newProductTitle,
            newProductPrice,
            newProductQuantity
        } = this.state;

        if (newProductTitle === "" || newProductPrice === 0) return;

        this.props.addProduct({
            item_id: Date.now() % 10,
            description: "",
            field1: newProductTitle,
            filed2: "",
            filed3: "",
            filed4: "",
            grocery_label: "pz.",
            images: [
                {
                    "image_file": img_custom,
                    "cropped": false
                }
            ],
            price: newProductPrice,
            price_label: "€" + newProductPrice,
            quantity: newProductQuantity,
            equivalence: 1,
            quantity_step: 1,
            done: 0,
            variety: undefined,
        });

        this.setState({
            newProductTitle: "",
            newProductPrice: "",
            newProductQuantity: 1,
        })

    }

    toggleSaveTooltip = () => {
        this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen })
    }

    toggleDeleteAllTooltip = () => {
        this.setState({ deleteAllTooltipOpen: !this.state.deleteAllTooltipOpen })
    }

    showCustomProductOnMobile = () => {
        if (isMobile) {
            document.getElementById("prodottoNonPresente").style.display = 'block';
        }
    }

    hideCustomProductOnMobile = () => {
        if (isMobile) {
            document.getElementById("prodottoNonPresente").style.display = 'none';
        }
    }

    sendGroceryListToMarket = () => {
        var tmpProducts = this.props.groceryList.list;

        tmpProducts.forEach((element) => {
            element.image_file = element.images[0] ? element.images[0].image_file : "";
        });

        var idParam = Math.random().toString(36).substr(2, 16);
        var product = this.props.groceryList.list;
        for(var i = 0; i < product.length; i++){
            product[i].description = product[i].description.substring(0, 80);
            product[i].field1 = product[i].field1.substring(0, 80);
            product[i].field2 = product[i].field2.substring(0, 80);
            product[i].field3 = product[i].field3.substring(0, 80);
            product[i].field4 = product[i].field4.substring(0, 80);
        }

        var exportObj = {
            "id_param_url": idParam,
            "id_campagna": this.props.groceryList.idCampaign,
            // "prodotti": this.props.groceryList.list
            "prodotti": product
        }

        axios.post('https://api.volantinointerattivo.net/api/write', exportObj).then(function (response) {
            var send_grocery_list_url = "https://clienti.volantinointerattivo.net/lista-spesa/invia?client_id=" + serverResponse.config.client_id + "&signboard_id=" + serverResponse.config.signboard_id + "&list_id=" + idParam;
            window.location = send_grocery_list_url;
        })
            .catch(function (error) {
                alert("Spiacenti, si è verificato un errore");
            });;
        // window.location = "http://clienti.volantinointerattivo.net/lista-spesa/invia?client_id=10&list_id=" + idParam;

    }

    render() {

        const {
            newProductTitle,
            newProductPrice,
            newProductQuantity
        } = this.state;

        var totPrice = 0;
        let list = this.props.groceryList.list.map((product, key) => {
            //totPrice += parseFloat(product.price) * parseInt(product.quantity);
            totPrice += parseFloat(product.currentPrice);
            return <GroceryProduct key={key} id={product.item_id} title={product.field1} variety={product.variety} price={product.currentPrice} grocery_label={product.grocery_label} quantity={product.quantity} quantity_step={product.quantity_step} done={product.done} images={product.images} />
        });

        return (
            <Popover
                className="header-popover-width custom-popover"
                isOpen={this.props.isOpen}
                target={this.props.target}
                placement={this.props.placement}
                toggle={this.props.toggle}
                trigger="block"
                boundariesElement="window"
            >
                <PopoverHeader
                    className="custom-popover-header text-center"
                    title={"Condividi Lista"}
                >
                    {!serverResponse.config.hide_share_grocery_list && <div style={{ display: "flex" }}>
                        <ShareGroceryList />

                        {/*<FontAwesomeIcon icon={faFileDownload} onClick={() => window.location = process.env.PUBLIC_URL + "/printGroceryList"} style={{ marginLeft: "10px", fontSize: '18px' }} title={"Stampa Lista"} />*/}
                    </div>}
                    <span style={{ marginLeft: !serverResponse.config.hide_share_grocery_list ? "-30px" : "" }}>La tua lista della spesa</span>
                    <FontAwesomeIcon icon={faTimes} onClick={this.props.toggle} />
                </PopoverHeader>
                <PopoverBody className="pa-0">
                    <div>
                        <Scrollbars
                            //style={{ height: "500px" }}
                            className="custom-scrollbars"
                            autoHide
                        >
                            {list.length > 0 && serverResponse.config.send_grocery_list_to_market && (totPrice >= serverResponse.config.grocery_list_min_value) && (
                                <div style={{ textAlign: "center", paddingTop: "15px", paddingBottom: "15px" }}>
                                    <Button className="vertical" color="primary" onClick={() => this.sendGroceryListToMarket()}>
                                        <FontAwesomeIcon icon={faStore} style={{ marginRight: "15px" }}></FontAwesomeIcon>
                                        Completa ordine
                                </Button>
                                </div>
                            )}

                            {list.length > 0 && serverResponse.config.send_grocery_list_to_market && (totPrice < serverResponse.config.grocery_list_min_value) && (
                                <div>
                                    <div style={{ textAlign: "center", paddingTop: "15px", paddingBottom: "5px" }}>
                                        <Button color="primary" disabled>
                                            <FontAwesomeIcon icon={faStore} style={{ marginRight: "15px" }}></FontAwesomeIcon>
                                            Completa ordine
                                </Button>
                                    </div>
                                    <div style={{ textAlign: "center", paddingBottom: "15px", width: '90%', margin: 'auto' }} >
                                        Raggiungi una spesa minima di {serverResponse.config.grocery_list_min_value}€ per poter inviare la tua lista
                                </div>
                                </div>
                            )}
                            {
                                list
                            }

                        </Scrollbars>



                        {/*Insert by hand*/}

                        <ul className="list-inline pa-10 ma-0 ul-bottom-border flex custom-popover-footer custom-total-price">
                            <li className="list-inline-item">
                                <h5 id="h5total">Totale: € {totPrice.toFixed(2)}</h5>
                            </li>
                            <li style={{ marginLeft: "auto" }}>
                                {/*<FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} onClick={() => this.props.deleteAll()} />*/}
                                <img id="btnDeleteAll" src={ClearChart} style={{ color: "#00ff00", marginLeft: "20px", width: "30px", cursor: "pointer" }} onClick={() => {this.props.deleteAll(); deleteAll(Array.from(this.props.groceryList.list))}} alt="img" />
                                <Tooltip placement="top" isOpen={this.state.deleteAllTooltipOpen} target="btnDeleteAll" toggle={this.toggleDeleteAllTooltip}>
                                    {strings.deleteAllBtnTooltip}
                                </Tooltip>
                            </li>
                        </ul>
                        {/* check value of serverResponse.config.hide_plus_product */}
                        {!serverResponse.config.hide_plus_product ?
                            <div style={{ padding: "10px", fontWeight: 'bold' }} onClick={() => this.showCustomProductOnMobile()}>
                                Inserisci un prodotto non presente
                            </div> : null}
                        {!serverResponse.config.hide_plus_product ?
                            <div id="prodottoNonPresente">
                                <div id="closeIcon">
                                    <FontAwesomeIcon icon={faTimes} onClick={() => this.hideCustomProductOnMobile()} />
                                </div>
                                <ul className="list-inline pa-10 ma-0 ul-bottom-border flex custom-popover-footer">
                                    <li className="list-inline-item pa-5 mobile-grocery-product-container">
                                        <h5>
                                            <Input type="text" name="product-title" id="product-title" placeholder="Nome prodotto" value={newProductTitle} onChange={this.handleChangeTitle} /> <br />
                                            {!serverResponse.config.hide_plus_product_price ?
                                            <Input
                                                type="number"
                                                name="product-price"
                                                id="product-price"
                                                placeholder="Prezzo stimato"
                                                style={{ marginTop: "-20px" }}
                                                value={newProductPrice}
                                                onChange={this.handleChangePrice}
                                                onKeyPress={this.handleKeyUp}
                                            /> : null }
                                        </h5>
                                        <div className="grocery-product-quantity">
                                            <FontAwesomeIcon icon={faMinusCircle} className="change-product-quantity-icon" style={{ marginRight: "10px" }} onClick={() => this.handleChangeQuantity(0)} />
                                            <h6>{newProductQuantity}</h6>
                                            <FontAwesomeIcon icon={faPlusCircle} className="change-product-quantity-icon" style={{ marginLeft: "10px" }} onClick={() => this.handleChangeQuantity(1)} />

                                            {/*<FontAwesomeIcon icon={faSave} style={{ color: "#00ff00", marginLeft: "20px" }} onClick={() => this.saveNewProduct()} />*/}
                                            <img id="btnSave" src={AddProd} style={{ color: "#00ff00", marginLeft: "20px", width: "30px", cursor: "pointer" }} onClick={() => this.saveNewProduct()} alt="img" />
                                            <Tooltip placement="top" isOpen={this.state.saveTooltipOpen} target="btnSave" toggle={this.toggleSaveTooltip}>
                                                {strings.saveBtnTooltip}
                                            </Tooltip>
                                        </div>
                                    </li>
                                </ul>
                            </div> : null
                        }
                    </div>
                </PopoverBody>
            </Popover>
        );
    }
}

const mapStateToProps = state => {

    return {
        groceryList: state.groceryList,
    };

}

function mapDispatchToProps(dispatch) {
    return {
        addProduct: (newProduct) => {
            dispatch(actions.addProduct(newProduct));
        },
        deleteAll: () => {
            dispatch(actions.deleteAll());
        }
    }
}



function deleteAll (items){
    items.forEach(item => {
        var image = document.getElementById("selected-" + item.item_id)
        if (image != null) {
            image.style['display'] = 'none';
        }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(GroceryList);