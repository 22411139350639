import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import GroceryList from '../floatingGroceryList/GroceryList';
import { connect } from 'react-redux';
import NewList from '../../assets/images/icons/new_list.png';

import { serverResponse } from '../../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';
import { forEach } from 'lodash';

class RightSidebar extends Component {

    state = {
        popoverOpen: false,
    }

    toggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });

        if (this.state.popoverOpen) {
            if (serverResponse.config.ga_active) {
                ReactGA.initialize(serverResponse.config.ga_tracking_id, {
                    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
                })
                ReactGA.pageview("/" + serverResponse.config.release_id + "/lista-spesa");
            }
        }
    }

    render() {
        if (serverResponse.config.marker_product_in_list){
            var items = Array.from(this.props.groceryList);
            setTimeout(() => {
                items.forEach(item => {
                    var image = document.getElementById("selected-" + item.item_id)
                    if (image != null) {
                        image.style['display'] = 'inline';
                    }
                })
            }, 1000);
        }
        let imgsrc = this.props.themeMapping.clientIcon;
        let imgsrcDef = require("assets/images/logo_client.png");
        return (
            <div
                id="right-sidebar"
                className="right-sidebar">

                <div className="right-sidebar-header">
                    <NavLink to={"/"} className="simple-text logo-mini">
                        <div className="logo-img">
                            <img className="right-sidebar-img-logo" src={imgsrc} onError={(e)=>{e.target.onerror = null; e.target.src=imgsrcDef}} alt="react-logo" />
                        </div>
                    </NavLink>
                </div>
                {!serverResponse.config.hide_grocery_list &&
                    <div>
                        <div className="right-sidebar-wrapper">
                            <ul className="nav">
                                <li>
                                    <NavLink
                                        to={"list.routepath"}
                                        className="nav-link main-list"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.toggle();
                                        }}>
                                        <div className="marker verticalListaSpesa" id="desktop-grocery" title={"La tua lista della spesa"}>
                                            <img id="" src={NewList} style={{ cursor: "pointer" }} alt="list" />
                                            <NotificationBadge count={this.props.groceryList.length} effect={Effect.SCALE} className="custom-grocery-notification-badge" />
                                        </div>



                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <GroceryList
                            isOpen={this.state.popoverOpen}
                            toggle={this.toggle}
                            placement={"left"}
                            target="desktop-grocery"
                            isMobile={false} />
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        groceryList: state.groceryList.list,
    };
}

export default connect(mapStateToProps)(RightSidebar);