import React, { Component } from "react";
import Sidebar from "components/sidebar/Sidebar";
import BottomSideBar from "components/bottomSidebar/BottomSidebar";
import BottomPrerollBar from "components/bottomPrerollbar/BottomPrerollBar";
import Radium from "radium";
import { isMobile } from 'react-device-detect';
import dashboardRoutes from "routes/dashboardRoutes";
import Header from "components/header/Header";
//import Footer from "components/footer/Footer";
import themeActions from "redux/themeChanger/actions.js";
import settingactions from "redux/themeSettings/actions";
import AppLocale from "languageProvider";
import {
    drawerWidth,
    miniDrawerWidth
} from "helper/constant";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { Scrollbars } from "react-custom-scrollbars";
import GlobalWrapper from "./global.style";

//Notification
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import { withRouter } from 'react-router-dom';
import RightSidebar from "../components/rightSidebar/RightSidebar";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowCircleUp,
    faAngleLeft,
    faAngleRight,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { serverResponse } from '../util/fakeServer';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css"
import SearchCarouselItem from "../components/searchBar/SearchCarouselItem";
import { isIE, isEdge } from 'react-device-detect';

import { Helmet } from "react-helmet";

const { changeTheme } = themeActions;
const { sidebarMini } = settingactions;

class DashboardLayout extends Component {
    state = {
        mini: this.props.themeSetting.sidebarMiniValue
            ? this.props.themeSetting.sidebarMiniValue === "on"
            : false,
        themeDrawer: true,
        statedrawerWidth: drawerWidth,
        stateminiDrawerWidth: miniDrawerWidth,
        displaySearchResults: "none",
        filteredProducts: [],
        searchText: "",
        scrollTop: 0
    };

    productFilter = (text, product) => {

        //let nameCheck = product.description.toLowerCase().includes(this.state.searchText.toLowerCase()) ? true : false;
        let nameCheck = product.description.toLowerCase().includes(text.toLowerCase()) ? true : false;
        return nameCheck;
    }

    componentWillMount = () => {
        let tmpProducts = [];
        let tmp = Object.assign({}, serverResponse);


        tmp.leaflet.pages.forEach((page) => {
            tmpProducts = tmpProducts.concat(page.interactivities);
        });

        tmpProducts.sort((a, b) => {
            const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
            if (aID === -1) return +1
            if (bID === -1) return -1
            return aID - bID
        });

        this.setState({
            products: tmpProducts,
        });
    }

    handleSearch = () => {
        if (this.state.searchText.length >= 3) {
            let filteredProducts = this.state.products.filter(this.productFilter.bind(this, this.state.searchText));
            this.setState({ filteredProducts: filteredProducts, displaySearchResults: filteredProducts.length > 0 ? "flex" : "none" }, () => {
                //console.log(filteredProducts);
            });
        } else if (this.state.searchText.length < 3) {
            this.setState({ displaySearchResults: "none", filteredProducts: [] });
        }
    }

    closeSearchCarousel = () => {
        this.setState({ displaySearchResults: "none", filteredProducts: [], searchText: "" });
    }

    componentDidUpdate = prevProps => {
        if (this.props.location !== prevProps.location) {
            const { scrollbars } = this.refs;
            scrollbars.scrollTop(0);
            this.setState({ displaySearchResults: "none", searchText: "" })
        }
    };

    componentWillReceiveProps = nextprops => {
        if (
            this.props.themeSetting.sidebarMiniValue !==
            nextprops.themeSetting.sidebarMiniValue
        ) {
            this.setState({
                mini: nextprops.themeSetting.sidebarMiniValue === "on"
            });
        }
    };

    drawerMiniMethod = () => {
        if (this.state.mini) {
            this.setState({ mini: false });
            this.props.sidebarMini("off");
        } else {
            this.setState({ mini: true });
            this.props.sidebarMini("on");
        }
    };

    forceDrawerMini = () => {
        //console.log('drawer forced to mini', this.state.mini);
        this.setState({ mini: true });
        this.props.sidebarMini("on");
    }

    themeSettingDrawer = () => {
        if (this.state.themeDrawer) {
            this.setState({ themeDrawer: false });
        } else {
            this.setState({ themeDrawer: true });
        }
    };

    mouseEnter = () => {
        if (this.state.mini) {
            this.setState({ stateminiDrawerWidth: drawerWidth });
        }
    };

    mouseLeave = () => {
        if (this.state.mini) {
            this.setState({ stateminiDrawerWidth: miniDrawerWidth });
        }
    };

    closeDrawer = () => {
        this.setState({ mini: true });
    };

    handleSearchTextChange = (e) => {
        this.setState({ searchText: e.target.value });
    }

    render() {
        let routeScrollHeight;
        const {
            mini,
            statedrawerWidth,
            stateminiDrawerWidth,
            showScrollTop
        } = this.state;
        const { layoutTheme, locale, themeSetting } = this.props;
        const currentAppLocale = AppLocale[locale];

        let routeArrowIcon = {
            backgroundColor: this.props.sidebarTheme.backgroundColor,
            color: this.props.sidebarTheme.textColor,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            position: "fixed",
            zIndex: 1,
            fontSize: "18px",
            padding: "4px 5px"
        };

        if (
            themeSetting.toolbarDisplayValue === "hide" &&
            themeSetting.footerDisplayValue === "hide"
        ) {
            routeScrollHeight = {
                height: "calc(100vh - 0px)"
            };
        } else if (themeSetting.toolbarDisplayValue === "hide") {
            routeScrollHeight = {
                height: "calc(100vh - 51px)"
            };
        } else if (themeSetting.footerDisplayValue === "hide") {
            routeScrollHeight = {
                height: "calc(100vh - 50px)"
            };
        } else {
            routeScrollHeight = {
                height: "calc(100vh - 116px)"
            };
        }

        const mainPanelWidth = {
            width: mini
                ? `calc(100% - ${miniDrawerWidth})`
                : `calc(100% - ${drawerWidth})`,
            "@media (max-width: 767.98px)": {
                width: '100%'
            }
        };

        const routeHeightStyle = {
            background: layoutTheme.backgroundColor,
            marginRight: "80px"
        }

        let listFiltered = this.state.filteredProducts.map((element, key) => {
            return <SearchCarouselItem element={element} key={key} />
        })

        let responsive = {
            0: { items: 8 },
            1024: { items: 8 },
        }

        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >

                <GlobalWrapper {...this.props}>
                    {!isMobile &&
                        < Sidebar
                            mini={mini}
                            drawerWidth={statedrawerWidth}
                            miniDrawerWidth={stateminiDrawerWidth}
                            onMouseEnter={this.mouseEnter}
                            onMouseLeave={this.mouseLeave}
                            closeDrawer={() => this.closeDrawer}
                            forceDrawerMini={this.forceDrawerMini}
                            themeMapping={this.props.themeMapping}
                            {...this.props}
                        />
                    }
                    {isMobile && serverResponse.config.has_pages &&
                        <BottomSideBar
                            forceDrawerMini={this.forceDrawerMini}
                        />
                    }

                    {isMobile && serverResponse.video_categories &&
                        <BottomPrerollBar
                            forceDrawerMini={this.forceDrawerMini}
                        />
                    }

                    <Helmet>
                        <title>{serverResponse.config.ogTitleMeta}</title>
                        <meta property="og:title" content={serverResponse.config.ogTitleMeta}></meta>
                        <meta property="og:url" content={serverResponse.config.shareFlyerURL}></meta>
                        <meta property="og:description" content={serverResponse.config.ogDescriptionMeta}></meta>
                        <meta property="og:image" content={serverResponse.config.ogImageMeta}></meta>
                        <meta property="og:type" content="website"></meta>
                    </Helmet>

                    <RightSidebar themeMapping={this.props.themeMapping} />

                    <div
                        id="main-panel"
                        className="main-panel"
                        ref="mainPanel"
                        style={mainPanelWidth}
                    >
                        {this.props.themeSetting.toolbarDisplayValue ===
                            "show" && (
                                <Header
                                    drawerMiniMethod={() => this.drawerMiniMethod}
                                    mini={mini}
                                    drawerWidth={statedrawerWidth}
                                    miniDrawerWidth={stateminiDrawerWidth}
                                    {...this.props}
                                    displaySearchResults={this.state.displaySearchResults}
                                    handleSearch2={this.handleSearch}
                                    searchText={this.state.searchText}
                                    handleSearchTextChange={this.handleSearchTextChange}
                                />
                            )}

                        <div className="drawer-handle-arrow-hide-topbar">
                            {themeSetting.toolbarDisplayValue !== "show" ? (
                                mini ? (
                                    <button
                                        style={routeArrowIcon}
                                        className="c-btn"
                                        onClick={this.drawerMiniMethod}
                                    >
                                        <i className="fas fa-arrow-right" />
                                    </button>
                                ) : (
                                        <button
                                            style={routeArrowIcon}
                                            className="c-btn"
                                            onClick={this.drawerMiniMethod}
                                        >
                                            <i className="fas fa-arrow-left" />
                                        </button>
                                    )
                            ) : (
                                    ""
                                )}
                        </div>

                        {themeSetting.toolbarDisplayValue !== "show" && (
                            <button
                                style={routeArrowIcon}
                                className="c-btn mini-drawer-menu-icon-hide-topbar"
                                onClick={this.drawerMiniMethod}
                            >
                                <i className="fas fa-bars" />
                            </button>
                        )}

                        <Scrollbars
                            style={routeScrollHeight}
                            autoHide
                            ref="scrollbars"
                            id="scrollbars"
                            onScroll={(e) => {
                                //this.setState({ scrollTop: e.target.scrollTop });
                                if (e.target.scrollTop > 300) {
                                    if (!this.state.showScrollTop) {
                                        this.setState({
                                            showScrollTop: true
                                        })
                                    }
                                } else {
                                    if (this.state.showScrollTop) {
                                        this.setState({
                                            showScrollTop: false
                                        })
                                    }
                                }
                            }}
                        >
                            {/* Search results */}
                            <div style={{
                                width: "calc(100% - 80px)",
                                paddingLeft: "3%",
                                paddingRight: "3%",
                                display: this.state.displaySearchResults,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white"
                            }}>


                                <div className="search-carousel-icons-container">
                                    <div className="search-carousel-icon-wrapper"></div>
                                    <FontAwesomeIcon icon={faAngleLeft} size="2x" onClick={() => this.Carousel.slidePrev()} className="search-carousel-icon" />
                                </div>

                                <AliceCarousel
                                    items={listFiltered}
                                    responsive={responsive}
                                    autoPlay={false}
                                    fadeOutAnimation={true}
                                    playButtonEnabled={false}
                                    disableAutoPlayOnAction={true}
                                    dotsDisabled={true}
                                    buttonsDisabled={true}
                                    ref={(el) => (this.Carousel = el)}
                                    infinite={false}
                                    mouseDragEnabled={false}
                                />
                                <div className="search-carousel-icons-container">
                                    <div className="search-carousel-icon-wrapper">
                                        <FontAwesomeIcon icon={faTimes} size="1x" onClick={this.closeSearchCarousel} className="search-carousel-icon search-carousel-close-icon" />
                                    </div>
                                    <FontAwesomeIcon icon={faAngleRight} size="2x" onClick={() => this.Carousel.slideNext()} className="search-carousel-icon" />
                                </div>

                            </div>
                            {/* Route Layout Start*/}
                            <div
                                className="route-height"
                                style={routeHeightStyle}
                            >
                                <Switch>
                                    <Route {...this.props}>
                                        {dashboardRoutes.map((prop, key) => {
                                            return (
                                                <Route
                                                    path={prop.path}
                                                    //component={prop.component}
                                                    render={(routeProps) => (
                                                        <prop.component {...routeProps} scrollTop={this.state.scrollTop} />
                                                    )}
                                                    key={key}

                                                />
                                            );
                                        })}
                                    </Route>
                                </Switch>
                            </div>
                            {/* Route Layout Finish*/}
                        </Scrollbars>

                        <FontAwesomeIcon
                            size="3x"
                            className="scroll-up-btn"
                            style={showScrollTop ? { display: "block" } : { display: "none" }}
                            icon={faArrowCircleUp}
                            onClick={() => {
                                if (isIE || isEdge) {
                                    this.refs.scrollbars.view.scrollTop = 0;
                                } else {
                                    this.refs.scrollbars.view.scroll({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }
                            }}
                        />

                        {/*
                        {themeSetting.footerDisplayValue === "show" && (
                            <Footer
                                {...this.props}
                                mini={mini}
                                drawerWidth={statedrawerWidth}
                                miniDrawerWidth={stateminiDrawerWidth}
                            />
                        )}
                        */}

                    </div>
                    <NotificationContainer />
                </GlobalWrapper>
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        LanguageSwitcher: state.LanguageSwitcher,
        locale: state.LanguageSwitcher.language.locale,
        authData: {
            token: state.auth.accessToken,
            isLogin: state.auth.isLogin
        },
        themeSetting: {
            toolbarAlignValue: state.themeSetting.toolbarAlignValue,
            footerAlignValue: state.themeSetting.footerAlignValue,
            sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
            toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
            footerDisplayValue: state.themeSetting.footerDisplayValue,
            sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
            transparentImage: state.themeSetting.transparentImage,
            activeLinkStyle: state.themeSetting.activeLinkStyle,
            sidebarMiniValue: state.themeSetting.sidebarMiniValue,
            sidebarTransParentActiveBack:
                state.themeSetting.sidebarTransParentActiveBack,
            sidebarTransParentActiveColor:
                state.themeSetting.sidebarTransParentActiveColor
        }
    };
};


export default withRouter(
    connect(
        mapStateToProps,
        {
            changeTheme,
            sidebarMini
        }
    )(Radium(DashboardLayout))
);