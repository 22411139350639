const leaflets = {
    "client": {
        "config": {
            "pdfPath": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pdf\/UNICASH_VOLANTINO.pdf",
            "noIconAnimated": false,
            "ogImageMeta_mobile": "https:\/\/dev02.interattivo.net\/media\/settings\/project\/image_meta_mobile\/Unicash-WA.jpg",
            "serverPath": "http://unicashdemo.interattivo.net/",
            "hover_color": "rgba(100,153,63,0.5)",
            "adsense": false,
            "hide_share_grocery_list": false,
            "hide_share_product": false,
            "hide_product_page": false,
            "hide_category_filter": false,
            "only_browsable": false,
            "hide_grocery_list": false,
            "hide_searchbar": false,
            "clientIcon": "https:\/\/dev02.interattivo.net\/media\/settings\/client_icon\/Icon_Interattivo.png",
            "client_code": "cxLQlyBIr1KeVmz6",
            "slug": "demo-unicash-non-cancellare",
            "flyer_status": 1,
            "release_id": "99",
            "pager": "buttons",
            "sidebar": [
                "leaflet",
                "products",
                "pages",
                "pdf",
                "full_screen",
                "share"
            ],
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 0,
            "has_pages": true,
            "category_banner": "https:\/\/dev02.interattivo.net\/media\/settings\/project\/category_banner\/Banner_desktop_unicash.jpg",
            "category_banner_mobile": "https:\/\/dev02.interattivo.net\/media\/settings\/project\/category_banner_mobile\/banner_mobile_unicash.jpg",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "hide_listPages": false,
            "type": "leaflet",
            "format": "13",
            "no_link_to_site": false,
            "iconAnimation": "verticalListaSpesa",
            "largeIcon": false,
            "hide_logo": false,
            "small_logo": false,
            "show_one_page": false,
            "version": 18,
            "filterBrand": 0,
            "filterUnderprice": 0,
            "marker_product_in_list": true,
            "marker": "circle_black",
            "client_id": 108,
            "signboard_id": 134,
            "expirationDate": "2022-11-08T20:13:00+01:00",
            "primary_color": "#cb7721",
            "secondary_color": "#8d5e30",
            "tertiary_color": "#5082c4",
            "brandImage": null,
            "underPriceImage": null,
            "shareFlyerTitle": "",
            "shareFlyerURL": "http://unicashdemo.interattivo.net",
            "shareProductSentence": "",
            "ogTitleMeta": "Unicash",
            "ogDescriptionMeta": "Unicash volantino interattivo",
            "ga_active": false,
            "ga_tracking_id": null,
            "ogImageMeta": "https:\/\/dev02.interattivo.net\/media\/settings\/project\/image_meta\/Unicassh_FB_share.png",
            "internal_banner_click": false,
            "external_banner_click": true,
            "href_banner": "https:\/\/catalogo.uniontrade.info\/",
            "product_banner": null
        },
        "video": {
            "show_only_once": true,
            "mandatory_seconds": 0,
            "videos": [
                {
                    page: 1,
                    url: "/media/unicash.mp4",
                    item_id: ""
                },
            ],
        },
        "leaflet": {
            "code": "56",
            "id_campaign": "",
            "name": "Demo unicash (non cancellare)",
            "index": {
                "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/Indice-unicash-demo_J6bThOc.png",
                "links": [
                    {
                        "id": 52,
                        "page": 2,
                        "blueprint": {
                            "top": 24.0840130276124,
                            "left": 5.861581920903955,
                            "width": 89.87544940934771,
                            "height": 8.09458910994023
                        },
                        "target": "_blank",
                        "url": "",
                        "type": "internal_link"
                    },
                    {
                        "id": 53,
                        "page": 4,
                        "blueprint": {
                            "top": 32.03786615190588,
                            "left": 5.861581920903955,
                            "width": 90.49178222907038,
                            "height": 8.421459786281057
                        },
                        "target": "_blank",
                        "url": "",
                        "type": "internal_link"
                    },
                    {
                        "id": 54,
                        "page": 6,
                        "blueprint": {
                            "top": 40.427546844653804,
                            "left": 5.2452491011813045,
                            "width": 90.64586543400104,
                            "height": 8.203546002053839
                        },
                        "target": "_blank",
                        "url": "",
                        "type": "internal_link"
                    },
                    {
                        "id": 55,
                        "page": 8,
                        "blueprint": {
                            "top": 48.70827064528812,
                            "left": 6.16974833076528,
                            "width": 89.72136620441705,
                            "height": 8.09458910994023
                        },
                        "target": "_blank",
                        "url": "",
                        "type": "internal_link"
                    },
                    {
                        "id": 60,
                        "page": -1,
                        "blueprint": {
                            "top": 79.04261848835023,
                            "left": 31.302003081664104,
                            "width": 39.46841294298921,
                            "height": 6.562251158347965
                        },
                        "target": "_blank",
                        "url": "https:\/\/interattivo.net\/",
                        "type": "url"
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Alimentari",
                    "subcategories": [
                        "Dispensa",
                        "Snack"
                    ]
                },
                {
                    "category_name": "Bevande",
                    "subcategories": [
                        "Birre",
                        "Bibite",
                        "Acqua",
                        "Liquori",
                        "Vini e Spumanti"
                    ]
                },
                {
                    "category_name": "Surgelati",
                    "subcategories": [
                        "Verdure",
                        "Ittico",
                        "Carne"
                    ]
                },
                {
                    "category_name": "Gastronomia",
                    "subcategories": [
                        "Salumi"
                    ]
                },
                {
                    "category_name": "Self Service",
                    "subcategories": [
                        "Latte"
                    ]
                },
                {
                    "category_name": "Igiene casa",
                    "subcategories": [
                        "Piatti",
                        "Extra"
                    ]
                },
                {
                    "category_name": "Casa e cucina",
                    "subcategories": [
                        "Altro"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_1_YtV8qeV.jpg",
                    "interactivities": [
                        {
                            "id": 228,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1916658786",
                            "field1": "Kikkoman",
                            "field2": "salsa ponzu",
                            "field3": "lemon\/ orange\/",
                            "field4": "chili",
                            "description": "Kikkoman salsa ponzu lemon\/ orange\/ chili",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "228",
                            "calcolo_prezzo": 1,
                            "price": "2.19",
                            "price_label": "\u20ac 2.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "lemon",
                                "orange",
                                "chili"
                            ],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 19.18095288249998,
                                "left": 0,
                                "width": 49.20390344119158,
                                "height": 23.675424682186364
                            },
                            "images": [
                                {
                                    "id": 414,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/csm_NEW-PONZU_Chili_250ml_EN_227a6baf53_I8qjQdJ.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 422,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_5_PiYQH7J.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 423,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_4_ZgyzJ37.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 291,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_YkWPcl8_9uEuXpQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "La nostra Ponzu Chili Kikkoman ha un gusto unico, caratterizzato da un perfetto equilibrio tra piccante, salato, dolce, speziato e acido. Partendo da una base di salsa di soia a fermentazione naturale, vengono aggiunti un tocco di limone e di chili piccante. Ottima da versare direttamente sulle pietanze o da utilizzare come salsa dip. La salsa Ponzu Chili di Kikkoman \u00e8 ideale con i frutti di mare, con il carpaccio, con il pesce, ostriche e gamberetti, oltre che con bistecche alla griglia, pollame e verdure. Questa salsa Kikkoman \u00e8 adatta per una dieta vegana. (Fonte: kikkoman.it)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/csm_NEW-PONZU_Chili_250ml_EN_227a6baf53_I8qjQdJ.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 229,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1734479296",
                            "field1": "Mizkan",
                            "field2": "condimento",
                            "field3": "mirin honteri",
                            "field4": "18 lt",
                            "description": "Mizkan condimento mirin honteri 18 lt",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "229",
                            "calcolo_prezzo": 1,
                            "price": "32.39",
                            "price_label": "\u20ac 32.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 19.07199599038637,
                                "left": 49.313045711350796,
                                "width": 49.35156651258345,
                                "height": 23.78438157429997
                            },
                            "images": [
                                {
                                    "id": 424,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_6_bDtliHY.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 292,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_Yg1Cd8a_HbjekQR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "active": true,
                                    "title": "Ingredienti",
                                    "data": {
                                        "specifiche": "Sciroppo di glucosio, acqua, aceto bianco, alcol di riso fermentato, zucchero, correttore di acidit\u00e0: acido citrico, melasse di canna (Fonte: mizkanchef.com)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/download_6_bDtliHY_7urdFgF.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 230,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0465206576",
                            "field1": "Heineken",
                            "field2": "bottiglia",
                            "field3": "15x66cl",
                            "field4": "",
                            "description": "Heineken bottiglia 15x66cl",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "230",
                            "calcolo_prezzo": 1,
                            "price": "0.87",
                            "price_label": "\u20ac 0.87",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "category_id": "347",
                            "subcategory_id": "356",
                            "blueprint": {
                                "top": 42.82459847115321,
                                "left": 0,
                                "width": 49.04340010272213,
                                "height": 24.1112522506408
                            },
                            "images": [
                                {
                                    "id": 426,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_1_xyl1RTw_uQwD3AX.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 293,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_vxB9Qyv_g2aZHVW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "video_type": "video_file",
                                        "open_modal": false,
                                        "link": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/video\/Heineken__The_Night_is_Young.mp4"
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 231,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2856766073",
                            "field1": "Monster",
                            "field2": "green energy drink",
                            "field3": "24x500ml",
                            "field4": "",
                            "description": "Monster green energy drink 24x500ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "231",
                            "calcolo_prezzo": 1,
                            "price": "0.79",
                            "price_label": "\u20ac 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "category_id": "347",
                            "subcategory_id": "355",
                            "blueprint": {
                                "top": 42.82459847115321,
                                "left": 49.46712891628146,
                                "width": 48.37570621468925,
                                "height": 23.78438157429997
                            },
                            "images": [
                                {
                                    "id": 425,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_A2k7X2l_aH444FC.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 294,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_pq28NpX_BlK3mwz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "video_type": "video_file",
                                        "open_modal": false,
                                        "link": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/video\/Monster_Energy.mp4"
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 232,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm7142241088",
                            "field1": "Goma wakame",
                            "field2": "salad",
                            "field3": "1 kg",
                            "field4": "",
                            "description": "Goma wakame salad 1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "232",
                            "calcolo_prezzo": 1,
                            "price": "4.49",
                            "price_label": "\u20ac 4.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "category_id": "352",
                            "subcategory_id": "384",
                            "blueprint": {
                                "top": 66.57720095192005,
                                "left": 0,
                                "width": 49.04340010272213,
                                "height": 23.893338466413585
                            },
                            "images": [
                                {
                                    "id": 427,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_2_cRcL7zk_1zUjtmZ.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 295,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_4volJ9i_vAyipfg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 234,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0055711098",
                            "field1": "Wofco",
                            "field2": "filetto di tonno",
                            "field3": "5 kg ca.",
                            "field4": "",
                            "description": "Wofco filetto di tonno 5 kg ca.",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "234",
                            "calcolo_prezzo": 4,
                            "price": "7.55",
                            "price_label": "\u20ac 7.55 al Kg.",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 66.14137338346562,
                                "left": 49.15896250642013,
                                "width": 50.12198253723677,
                                "height": 24.1112522506408
                            },
                            "images": [
                                {
                                    "id": 428,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_3_Si6h7zn_iLWCt3V.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 297,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_e6NNIbl_4ECHCVy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_2_uGFJCni.jpg",
                    "interactivities": [
                        {
                            "id": 235,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1330403013",
                            "field1": "Fettine",
                            "field2": "di seppia",
                            "field3": "per sushi",
                            "field4": "25x160g",
                            "description": "Fettine di seppia per sushi 25x160g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "235",
                            "calcolo_prezzo": 1,
                            "price": "3.77",
                            "price_label": "\u20ac 3.77",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 5.125513799844374,
                                "left": 2.3176682074987163,
                                "width": 31.47791474062661,
                                "height": 21.823157516255005
                            },
                            "images": [
                                {
                                    "id": 429,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_4_WinZbiq_iAIOYjs.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 298,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_mJRHWRr_m1sSoNR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 236,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5300459659",
                            "field1": "Calamari puliti",
                            "field2": "",
                            "field3": "6x2 kg",
                            "field4": "",
                            "description": "Calamari puliti 6x2 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "236",
                            "calcolo_prezzo": 1,
                            "price": "19.69",
                            "price_label": "\u20ac 19.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 5.343427584071593,
                                "left": 33.750642013353875,
                                "width": 30.553415511042626,
                                "height": 21.169416163573352
                            },
                            "images": [
                                {
                                    "id": 430,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_5_hb6bJFJ_BJP31ZR.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 299,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_8kTTgqH_PAYOhvj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 237,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm7794161254",
                            "field1": "Anelli",
                            "field2": "calamaro iqf",
                            "field3": "12x400 g",
                            "field4": "",
                            "description": "Anelli calamaro iqf 12x400 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "237",
                            "calcolo_prezzo": 1,
                            "price": "6.99",
                            "price_label": "\u20ac 6.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 5.343427584071593,
                                "left": 65.18361581920904,
                                "width": 31.32383153569594,
                                "height": 21.605243732027787
                            },
                            "images": [
                                {
                                    "id": 431,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/images_i0lRgKX.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 300,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_h2H6RKS_1PwJLiG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "active": true,
                                    "title": "Anelli di calamari al forno",
                                    "data": {
                                        "ingredients": "Calamari medi 600 g, Pangrattato 200 g, Paprika dolce 20 g, Olio extravergine d'oliva 2 cucchiai, Sale fino q.b.",
                                        "recipe": "Per realizzare gli anelli di calamari al forno iniziate sciacquando i calamari sotto l'acqua corrente, poi staccate la testa dal mantello e tenetela da parte. Estraete la penna di cartilagine trasparente. Passate nuovamente il calamaro sotto acqua corrente per rimuovere le interiora e la sacca di inchiostro se presente. Passate quindi ad eliminare la pelle esterna: incidete con un coltellino la parte finale del mantello e tirate via completamente il rivestimento con le mani o aiutandovi con un coltellino, rimuovete anche le pinne. Ora riprendete la testa che avevate messo da parte e separatela dai tentacoli. Sciacquate accuratamente tutte le parti del calamaro pulite sotto acqua corrente. Tagliate il mantello ad anelli di un centimetro di spessore e unite questi ai tentacoli. Ora occupatevi della panatura: ponete in una ciotola il pangrattato e unite la paprika dolce e un cucchiaino di sale. Versate la miscela ottenuta in una teglia larga, disponete gli anelli di calamaro e i tentacoli 8 mescolateli in modo che la panatura aderisca su tutti i lati. poi passate i calamari in un setaccio a maglie larghe, ponete sotto il setaccio un recipiente e scuotete il setaccio per eliminare la panatura in eccesso. Ungete una teglia con l'olio, poi foderatela con carta da forno, facendo una leggera pressione con le mani in modo da fare aderire meglio la carta. Trasferite nella teglia gli anelli e i tentacoli dei calamari  impanati. Versate l'olio e cuocete in forno statico preriscaldato per 25 minuti a 180\u00b0 oppure in forno ventilato a 160\u00b0 per circa 15-20 minuti. Terminata la cottura i vostri anelli di calamari al forno saranno pronti per essere portati in tavola e gustati ben caldi.",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/Anelli-di-calamari-al-forno_780x520_wm.webp"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 238,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2443471618",
                            "field1": "Calamaro",
                            "field2": "patagonico pulito",
                            "field3": "6x900g",
                            "field4": "",
                            "description": "Calamaro patagonico pulito 6x900g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "238",
                            "calcolo_prezzo": 1,
                            "price": "8.79",
                            "price_label": "\u20ac 8.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 27.025849114679854,
                                "left": 2.311248073959938,
                                "width": 31.015665125834616,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 432,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_6_hV5a6c4_p6YtMZU.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 301,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_EZdghr8_yW7xW51.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 239,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3667128483",
                            "field1": "Carote",
                            "field2": "baby",
                            "field3": "4x2.5 kg",
                            "field4": "",
                            "description": "Carote baby 4x2.5 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "239",
                            "calcolo_prezzo": 1,
                            "price": "2.78",
                            "price_label": "\u20ac 2.78",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 26.263150869884587,
                                "left": 34.058808423215204,
                                "width": 30.707498715973294,
                                "height": 21.605243732027787
                            },
                            "images": [
                                {
                                    "id": 433,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_7_4Rv5hZS.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 302,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_bEwWH2X_yTUvUBh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 240,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2295835659",
                            "field1": "Cozze cilene",
                            "field2": "intere",
                            "field3": "10x500 g",
                            "field4": "",
                            "description": "Cozze cilene intere 10x500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "240",
                            "calcolo_prezzo": 1,
                            "price": "1.57",
                            "price_label": "\u20ac 1.57",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 26.698978438339026,
                                "left": 65.18361581920904,
                                "width": 31.169748330765277,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 434,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_8_Q8ZObgs.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 307,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_226zDGX_yHnhEdf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "active": true,
                                    "title": "Pasta cin cozze",
                                    "data": {
                                        "ingredients": "Pasta 250 g, Cozze surgelate sgusciate 400 g, Pomodoro pelato 25 g, Cipolla 1 spicchio, Aglio 1 ciuffo, Prezzemolo tritato q.b., Sale q.b., Pepe q.b., Peperoncino q.b.",
                                        "recipe": "Per la realizzazione della tua velocissima e gustosa pasta con cozze surgelate e sgusciate, la prima cosa che dovrete fare e immergere le cozze congelate in acqua fredda, per agevolare il scongelamento. Se invece avete abbastanza tempo, lasciatele scongelare a temperatura ambiente. Nel frattempo, in un pentolino fai un soffritto di cipolla tritata finemente e uno spicchio d\u2019aglio intero (in modo da toglierlo facilmente). Quando la cipolla diventa traslucida, aggiungi i pomodori pelati, aggiungete un bicchiere d\u2019acqua poco sale una spolverata di pepe e un pizzico di peperoncino se piace. Nelle cozze almeno un pizzico rende tutto pi\u00f9 saporito, fidatevi! Copri la pentola tenendo il coperchio semi chiuso, aiutandoti con un mestolo e lascia cuocere fin quando non si asciuga l\u2019acqua che avete messo. Mentre il sugo di pomodoro cuoce, pulisci le cozze surgelate sgusciate ormai scongelate. Baster\u00e0 aprire le piccole \u201clabbra\u201d che si chiamano mantelli e togliere, dove presente, la barbetta che si chiama \u201cbisso\u201d. Quando il sugo \u00e8 abbastanza denso aggiungi le cozze e lasciale cuocere non pi\u00f9 di 10 minuti. ricorda che le cozze sono precotte e quindi non devono cuocere, ma devono cuocere giusto il tempo per dare sapore al sugo di pomodoro. Se cotte troppo si sfalderanno facilmente. Quando la pasta sar\u00e0 cotta, versate il sugo sulla pasta, io solitamente utilizzo spaghetti o linguine. Amalgamate bene il condimento alla pasta e prima di impiattare spolverate con del prezzemolo tritato finemente. Servite subito la vostra pasta con cozze surgelate sgusciate. (Fonte: blog.giallozafferano.it)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/spaghetti-con-cozze-surgelate-sgusciate-720x484.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 241,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5066321583",
                            "field1": "Polpa di vongole",
                            "field2": "",
                            "field3": "10x800 g",
                            "field4": "",
                            "description": "Polpa di vongole 10x800 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "241",
                            "calcolo_prezzo": 1,
                            "price": "3.99",
                            "price_label": "\u20ac 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 47.94557240049285,
                                "left": 65.02953261427837,
                                "width": 31.940164355418595,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 309,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_QPtXhPr_DNZZDNT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 242,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2288064953",
                            "field1": "Code di gamberi",
                            "field2": "c1",
                            "field3": "",
                            "field4": "",
                            "description": "Code di gamberi c1",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "242",
                            "calcolo_prezzo": 4,
                            "price": "12.99",
                            "price_label": "\u20ac 12.99 al Kg.",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 47.5097448320384,
                                "left": 32.98022598870056,
                                "width": 31.32383153569594,
                                "height": 21.169416163573352
                            },
                            "images": [
                                {
                                    "id": 436,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_9_R14Qt9L.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 310,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_JvLLtiZ_oSYjznj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 243,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6315843193",
                            "field1": "Polpa di granchio",
                            "field2": "",
                            "field3": "20x300 g",
                            "field4": "",
                            "description": "Polpa di granchio 20x300 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "243",
                            "calcolo_prezzo": 1,
                            "price": "7.04",
                            "price_label": "\u20ac 7.04",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 47.83661550837923,
                                "left": 2.3176682074987163,
                                "width": 30.09116589625064,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 435,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/images_1_ngKg5sW.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 311,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_5vd9j04_3OPx8en.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 244,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3675511438",
                            "field1": "Cosce di pollo",
                            "field2": "",
                            "field3": "10 kg",
                            "field4": "",
                            "description": "Cosce di pollo 10 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "244",
                            "calcolo_prezzo": 1,
                            "price": "13.49",
                            "price_label": "\u20ac 13.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Carne",
                            "category_id": "352",
                            "subcategory_id": "373",
                            "blueprint": {
                                "top": 69.84590771532832,
                                "left": 2.779917822290704,
                                "width": 31.63199794555727,
                                "height": 19.75297656609643
                            },
                            "images": [
                                {
                                    "id": 437,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/images_2_ytuBbll.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 312,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_onP54mi_wUSyUVA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 245,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm9503684423",
                            "field1": "Burger di pollo",
                            "field2": "impanati",
                            "field3": "950 g",
                            "field4": "",
                            "description": "Burger di pollo impanati 950 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "245",
                            "calcolo_prezzo": 1,
                            "price": "3.99",
                            "price_label": "\u20ac 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Carne",
                            "category_id": "352",
                            "subcategory_id": "373",
                            "blueprint": {
                                "top": 68.86529568630584,
                                "left": 35.75372367745249,
                                "width": 28.704417051874678,
                                "height": 20.842545487232524
                            },
                            "images": [
                                {
                                    "id": 438,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_10_ZDs44sg.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 313,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_T7DNa9e_LDUI1Aw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 246,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3084067626",
                            "field1": "Iqf",
                            "field2": "",
                            "field3": "10x800 g",
                            "field4": "",
                            "description": "Iqf 10x800 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "246",
                            "calcolo_prezzo": 1,
                            "price": "2.49",
                            "price_label": "\u20ac 2.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 69.19216636264667,
                                "left": 67.28941961992808,
                                "width": 29.166666666666668,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 314,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_10HHDfc_6gWn29v.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_3_Li8JEDo.jpg",
                    "interactivities": [
                        {
                            "id": 247,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1341879153",
                            "field1": "Polpa di acai",
                            "field2": "",
                            "field3": "4x1 kg",
                            "field4": "",
                            "description": "Polpa di acai 4x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "247",
                            "calcolo_prezzo": 1,
                            "price": "9.59",
                            "price_label": "\u20ac 9.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 5.125513799844374,
                                "left": 2.7799178222907037,
                                "width": 30.39933230611197,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 439,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_11_7ZbSxi7.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 322,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_8sQw9t2_x02wi8K.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 248,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0764328010",
                            "field1": "Anelli ciuffi",
                            "field2": "di calamaro 20%",
                            "field3": "6x1 kg",
                            "field4": "",
                            "description": "Anelli ciuffi di calamaro 20% 6x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "248",
                            "calcolo_prezzo": 1,
                            "price": "9.35",
                            "price_label": "\u20ac 9.35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 4.907600015617154,
                                "left": 33.288392398561896,
                                "width": 31.015665125834616,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 440,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_12_dVwNA5c.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 323,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_phfqxxC_hfOHPXW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 249,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3709635714",
                            "field1": "Fondomar",
                            "field2": "tentacoli cotti",
                            "field3": "tagliati",
                            "field4": "",
                            "description": "Fondomar tentacoli cotti tagliati",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "249",
                            "calcolo_prezzo": 1,
                            "price": "7.65",
                            "price_label": "\u20ac 7.65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 5.125513799844374,
                                "left": 67.032614278377,
                                "width": 31.015665125834616,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 441,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_13_Iq4COoW.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 324,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_lHSIvL7_NEPEY2R.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 250,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm7494965792",
                            "field1": "Amarras",
                            "field2": "tentacoli interi",
                            "field3": "di totano",
                            "field4": "10x1 kg",
                            "description": "Amarras tentacoli interi di totano 10x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "250",
                            "calcolo_prezzo": 1,
                            "price": "4.22",
                            "price_label": "\u20ac 4.22",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 25.93628019354376,
                                "left": 63.642783769902415,
                                "width": 34.25141242937853,
                                "height": 19.099235213414776
                            },
                            "images": [
                                {
                                    "id": 442,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_14_CoYUwyS.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 325,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_FejNYGL_rBxTOHJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 251,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm4697103534",
                            "field1": "Tranci",
                            "field2": "di granchio M",
                            "field3": "12x500 gr",
                            "field4": "",
                            "description": "Tranci di granchio M 12x500 gr",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "251",
                            "calcolo_prezzo": 1,
                            "price": "5.29",
                            "price_label": "\u20ac 5.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 26.263150869884587,
                                "left": 2.779917822290704,
                                "width": 32.24833076527992,
                                "height": 19.317148997641993
                            },
                            "images": [
                                {
                                    "id": 326,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_tsVi1V9_hfmxXdS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 252,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3717001757",
                            "field1": "Costine di suino",
                            "field2": "affumicate",
                            "field3": "al kg",
                            "field4": "",
                            "description": "Costine di suino affumicate al kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "252",
                            "calcolo_prezzo": 4,
                            "price": "5.39",
                            "price_label": "\u20ac 5.39 al Kg.",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Carne",
                            "category_id": "352",
                            "subcategory_id": "373",
                            "blueprint": {
                                "top": 48.272443076833675,
                                "left": 2.7734976887519256,
                                "width": 31.015665125834616,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 443,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_15_qUX2NFV.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 327,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_XCiNAAN_4EJSdx6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 253,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1717136892",
                            "field1": "Prosciutto cotto",
                            "field2": "pizzaiolo",
                            "field3": "1\/2 s\/v",
                            "field4": "",
                            "description": "Prosciutto cotto pizzaiolo 1\/2 s\/v",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "253",
                            "calcolo_prezzo": 4,
                            "price": "3.14",
                            "price_label": "\u20ac 3.14 al Kg.",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "category_id": "348",
                            "subcategory_id": "366",
                            "blueprint": {
                                "top": 48.49035686106089,
                                "left": 34.52105803800719,
                                "width": 30.553415511042626,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 444,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_16_bhEQlVz.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 328,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_Nfiictm_aAu4WYN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "active": true,
                                    "title": "",
                                    "data": "http:\/\/www.trinitasalumi.it\/prosciutti\/trinita-prosciutto-cotto-pizzaiolo\/"
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 254,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm8890240388",
                            "field1": "Trinit\u00e0",
                            "field2": "prosciutto praga",
                            "field3": "1\/2 sv kg",
                            "field4": "",
                            "description": "Trinit\u00e0 prosciutto praga 1\/2 sv kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "254",
                            "calcolo_prezzo": 4,
                            "price": "3.95",
                            "price_label": "\u20ac 3.95 al Kg.",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "category_id": "348",
                            "subcategory_id": "366",
                            "blueprint": {
                                "top": 48.81722753740172,
                                "left": 65.33769902413971,
                                "width": 31.32383153569594,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 415,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/TRINITA-PROSCIUTTO-COTTO-PRAGA_M25BoUJ.png",
                                    "cropped": false
                                },
                                {
                                    "id": 329,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_DB6WM8v_Ouve5Kp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Prosciutto cotto prodotto da coscia suina fresca disossata, salata e aromatizzata in salamoia, stampata a mano e cotta a vapore secondo tradizione. La carne utilizzata \u00e8 di provenienza CE. Le modalit\u00e0 di lavorazione definiscono la qualit\u00e0 \u201ctradizionale\u201d del prodotto. Il prodotto presenta un aroma tipico; sapido, speziato leggermente affumicato. Il prodotto \u00e8 disponibile in formato castagna intero e a met\u00e0, confezionato sottovuoto in bustone alluminato e pastorizzato. (Fonte: trinitasalumi.it)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/TRINITA-PROSCIUTTO-COTTO-PRAGA.png"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 255,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6266339905",
                            "field1": "Zio camillo",
                            "field2": "salsa roquefort",
                            "field3": "500 g",
                            "field4": "",
                            "description": "Zio camillo salsa roquefort 500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "255",
                            "calcolo_prezzo": 1,
                            "price": "4.58",
                            "price_label": "\u20ac 4.58",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 69.08320947053306,
                                "left": 2.3176682074987167,
                                "width": 30.707498715973294,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 445,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_18_f3P2Xxq.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 330,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_Z6Zw2m1_VKLc7rf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 256,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6923957932",
                            "field1": "Zio camillo",
                            "field2": "salsa cheddar",
                            "field3": "500 g",
                            "field4": "",
                            "description": "Zio camillo salsa cheddar 500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "256",
                            "calcolo_prezzo": 1,
                            "price": "3.14",
                            "price_label": "\u20ac 3.14",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 69.62799393110112,
                                "left": 33.288392398561896,
                                "width": 32.09424756034925,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 446,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_17_BB0wVJi.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 331,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_RhA6lEY_l4qRv9t.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 257,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm8613491656",
                            "field1": "Cheddar",
                            "field2": "trancio",
                            "field3": "",
                            "field4": "",
                            "description": "Cheddar trancio",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "257",
                            "calcolo_prezzo": 4,
                            "price": "7.19",
                            "price_label": "\u20ac 7.19 al Kg.",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 68.86529568630584,
                                "left": 66.10811504879302,
                                "width": 30.707498715973294,
                                "height": 21.38732994780057
                            },
                            "images": [
                                {
                                    "id": 447,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_19_7gfAQQC.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 332,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_K6jLNAL_wPegNhE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_4_XgvTWyq.jpg",
                    "interactivities": [
                        {
                            "id": 258,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2392475695",
                            "field1": "Fecola",
                            "field2": "di patate",
                            "field3": "25 kg",
                            "field4": "",
                            "description": "Fecola di patate 25 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "258",
                            "calcolo_prezzo": 1,
                            "price": "16.99",
                            "price_label": "\u20ac 16.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 5.125513799844374,
                                "left": 2.625834617360041,
                                "width": 30.707498715973294,
                                "height": 21.060459271459738
                            },
                            "images": [
                                {
                                    "id": 416,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_yQqwKav.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 333,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_ZReDMH9_zPEP4J8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Da un punto di vista chimico, non esistono significative differenze tra i vari tipi di amido e fecola, salvo la percentuale di amilosio\/ amilopectina e la morfologia dei granuli, che hanno struttura e grandezza diversa a seconda delle piante da cui derivano. Se osservata al microscopio, la fecola si caratterizza per la presenza di granuli isolati, piuttosto grandi, di forma ovale, simili ad una pera o ad una conchiglia, con ilo eccentrico e striature marcate. (Fonte: my-personaltrainer.it)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/download.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 263,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5123601626",
                            "field1": "Farina",
                            "field2": "di riso",
                            "field3": "glutinoso",
                            "field4": "50x400 g",
                            "description": "Farina di riso glutinoso 50x400 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "263",
                            "calcolo_prezzo": 1,
                            "price": "0.99",
                            "price_label": "\u20ac 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 5.125513799844374,
                                "left": 33.288392398561896,
                                "width": 31.32383153569594,
                                "height": 21.169416163573352
                            },
                            "images": [
                                {
                                    "id": 448,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_imQq0e4_E6EpNo7.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 338,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_I9AlXIz_IgfIv6x.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 264,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6840045834",
                            "field1": "Biyori",
                            "field2": "riso per sushi",
                            "field3": "10x1 kg",
                            "field4": "",
                            "description": "Biyori riso per sushi 10x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "264",
                            "calcolo_prezzo": 1,
                            "price": "1.39",
                            "price_label": "\u20ac 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 5.343427584071593,
                                "left": 64.87544940934771,
                                "width": 30.861581920903955,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 449,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_1_dR7rRTq_RvINNqn.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 339,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_GYMQWrI_zJeuEH5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 265,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0808953887",
                            "field1": "Nissin",
                            "field2": "tempura ko",
                            "field3": "10 kg",
                            "field4": "",
                            "description": "Nissin tempura ko 10 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "265",
                            "calcolo_prezzo": 1,
                            "price": "24.99",
                            "price_label": "\u20ac 24.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 26.154193977770976,
                                "left": 2.9340010272213664,
                                "width": 30.09116589625064,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 450,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_2_RIenmpl_EfPLFmz.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 340,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_FFot8PA_IYbNi9l.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 266,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5340159839",
                            "field1": "Nissin",
                            "field2": "soba al te verde",
                            "field3": "30x200 g",
                            "field4": "",
                            "description": "Nissin soba al te verde 30x200 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "266",
                            "calcolo_prezzo": 1,
                            "price": "2.49",
                            "price_label": "\u20ac 2.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 26.154193977770976,
                                "left": 33.442475603492554,
                                "width": 31.015665125834616,
                                "height": 20.95150237934613
                            },
                            "images": [
                                {
                                    "id": 451,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_3_cQrtElr_ruiujWv.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 341,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_ey56dKM_PLbNMNo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 267,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1340131541",
                            "field1": "Noodles istantanei",
                            "field2": "gusto pollo\/ verdure",
                            "field3": "12x60 g",
                            "field4": "",
                            "description": "Noodles istantanei gusto pollo\/ verdure 12x60 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "267",
                            "calcolo_prezzo": 1,
                            "price": "0.59",
                            "price_label": "\u20ac 0.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "pollo",
                                "verdure"
                            ],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 26.263150869884587,
                                "left": 64.72136620441705,
                                "width": 30.861581920903955,
                                "height": 20.95150237934613
                            },
                            "images": [
                                {
                                    "id": 452,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_4_fwWftjq_VhVXNOA.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 342,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_XvF6DH4_bIvvNGJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 268,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5706568477",
                            "field1": "Lina",
                            "field2": "fagioli neri",
                            "field3": "12x500 g",
                            "field4": "",
                            "description": "Lina fagioli neri 12x500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "268",
                            "calcolo_prezzo": 1,
                            "price": "0.79",
                            "price_label": "\u20ac 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 47.400787939924804,
                                "left": 2.625834617360041,
                                "width": 30.707498715973294,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 453,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_mRJjFSO_Sted2Rt.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 343,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_tHDyG7d_HyxSPBt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 269,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5514140545",
                            "field1": "Pezzi",
                            "field2": "di bamboo",
                            "field3": "in acqua",
                            "field4": "6x2950 g",
                            "description": "Pezzi di bamboo in acqua 6x2950 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "269",
                            "calcolo_prezzo": 1,
                            "price": "3.49",
                            "price_label": "\u20ac 3.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 47.07391726358397,
                                "left": 33.442475603492554,
                                "width": 31.169748330765277,
                                "height": 20.842545487232524
                            },
                            "images": [
                                {
                                    "id": 344,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_TBLabMD_ZsH9Ofy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 270,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3948425380",
                            "field1": "Dashi istantaneo",
                            "field2": "gusto kombu",
                            "field3": "10x1 kg",
                            "field4": "",
                            "description": "Dashi istantaneo gusto kombu 10x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "270",
                            "calcolo_prezzo": 1,
                            "price": "10.99",
                            "price_label": "\u20ac 10.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 46.96496037147036,
                                "left": 64.72136620441705,
                                "width": 30.861581920903955,
                                "height": 21.060459271459738
                            },
                            "images": [
                                {
                                    "id": 454,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_2_ybpVHyH_RXyAd72.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 345,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_gZ5Vcmq_rn2kEek.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 271,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1056815687",
                            "field1": "Bivori",
                            "field2": "wasabi in polvere",
                            "field3": "10x30 g",
                            "field4": "",
                            "description": "Bivori wasabi in polvere 10x30 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "271",
                            "calcolo_prezzo": 1,
                            "price": "0.85",
                            "price_label": "\u20ac 0.85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 67.88468365728336,
                                "left": 2.3176682074987167,
                                "width": 20.075757575757578,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 419,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_1_eGvdU5h.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 346,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_5sPm5r5_emdu5Ne.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "titolo": "Descizione",
                                        "testo": "Wasabi in polvere; aggiungere pochissima acqua per renderlo pastoso e servirlo. Rispetto al wasabi gi\u00e0 pronto in tubetto, pi\u00f9 pratico, quello in polvere ha un aroma e un sapore pi\u00f9 ricco e sostenuto che meglio si addice al sushi. (Fonte: sushitalia.com)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/download_1.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 272,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1161423796",
                            "field1": "Grace",
                            "field2": "latte di cocco",
                            "field3": "12x400ml",
                            "field4": "",
                            "description": "Grace latte di cocco 12x400ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "272",
                            "calcolo_prezzo": 1,
                            "price": "0.89",
                            "price_label": "\u20ac 0.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "category_id": "351",
                            "subcategory_id": "385",
                            "blueprint": {
                                "top": 68.3205112257378,
                                "left": 22.810734463276834,
                                "width": 26.39316897791474,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 455,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_3_eWL85ej_W4SmO9j.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 347,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_24MLxf3_5vegX7G.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 273,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm7966123658",
                            "field1": "Cock",
                            "field2": "salsa dolce",
                            "field3": "al chili per involtini primavera",
                            "field4": "24x290 g",
                            "description": "Cock salsa dolce al chili per involtini primavera 24x290 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "273",
                            "calcolo_prezzo": 1,
                            "price": "1.49",
                            "price_label": "\u20ac 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 68.42946811785141,
                                "left": 49.46712891628146,
                                "width": 22.541088854648176,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 456,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_4_KFDIWvR_pNaIalV.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 348,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_NGy0t6o_tUEwI39.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 274,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0600424162",
                            "field1": "Olio puro",
                            "field2": "di sesamo",
                            "field3": "185 ml",
                            "field4": "",
                            "description": "Olio puro di sesamo 185 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "274",
                            "calcolo_prezzo": 1,
                            "price": "2.59",
                            "price_label": "\u20ac 2.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 68.10259744151058,
                                "left": 70.83333333333331,
                                "width": 25.16050333846944,
                                "height": 21.38732994780057
                            },
                            "images": [
                                {
                                    "id": 457,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_5_wdlm22d_kF3912T.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 349,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_e5C9VIu_zUmCyr4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_5_iERskx6.jpg",
                    "interactivities": [
                        {
                            "id": 275,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2907456077",
                            "field1": "Salsa",
                            "field2": "yakisoba",
                            "field3": "2x500 g",
                            "field4": "",
                            "description": "Salsa yakisoba 2x500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "275",
                            "calcolo_prezzo": 1,
                            "price": "2.89",
                            "price_label": "\u20ac 2.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 5.016556907730764,
                                "left": 1.0850025680534154,
                                "width": 31.63199794555727,
                                "height": 21.496286839914177
                            },
                            "images": [
                                {
                                    "id": 458,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_6_bwx06pG_N3XZufD.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 350,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_eOqNXSc_FDvngqB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 276,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0133945399",
                            "field1": "Tortilla",
                            "field2": "D30 18p",
                            "field3": "6x1440 g",
                            "field4": "",
                            "description": "Tortilla D30 18p 6x1440 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "276",
                            "calcolo_prezzo": 1,
                            "price": "2.59",
                            "price_label": "\u20ac 2.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 5.234470691957982,
                                "left": 32.98022598870056,
                                "width": 31.47791474062661,
                                "height": 21.496286839914177
                            },
                            "images": [
                                {
                                    "id": 459,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_7_OuX6zmc_GSFadOx.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 351,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_V9IKp5B_yXx2RqK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 277,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5644615190",
                            "field1": "Pasta di soia",
                            "field2": "",
                            "field3": "500 g",
                            "field4": "",
                            "description": "Pasta di soia 500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "277",
                            "calcolo_prezzo": 1,
                            "price": "1.79",
                            "price_label": "\u20ac 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 4.907600015617154,
                                "left": 65.02953261427837,
                                "width": 31.47791474062661,
                                "height": 21.496286839914177
                            },
                            "images": [
                                {
                                    "id": 460,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_8_Ir3yYLt_Dleg1EK.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 352,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_AyGYhG5_Q4vovGd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 278,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm8596016288",
                            "field1": "Tortilla chips",
                            "field2": "rotonde\/",
                            "field3": "classiche",
                            "field4": "",
                            "description": "Tortilla chips rotonde\/ classiche",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "278",
                            "calcolo_prezzo": 1,
                            "price": "0.69",
                            "price_label": "\u20ac 0.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack",
                            "category_id": "346",
                            "subcategory_id": "386",
                            "blueprint": {
                                "top": 26.59002154622542,
                                "left": 0.7704160246533127,
                                "width": 31.786081150487927,
                                "height": 21.27837305568696
                            },
                            "images": [
                                {
                                    "id": 461,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_9_D0rLwmS_cQe8bNS.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 462,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_10_zL9jQr2_pDENCWD.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 353,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_V4OdbRz_bbAHa8O.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 279,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2056742333",
                            "field1": "Pro",
                            "field2": "chips di banana",
                            "field3": "saltate",
                            "field4": "24x75 g",
                            "description": "Pro chips di banana saltate 24x75 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "279",
                            "calcolo_prezzo": 1,
                            "price": "0.59",
                            "price_label": "\u20ac 0.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack",
                            "category_id": "346",
                            "subcategory_id": "386",
                            "blueprint": {
                                "top": 26.698978438339026,
                                "left": 33.134309193631225,
                                "width": 31.940164355418595,
                                "height": 11.363295873348509
                            },
                            "images": [
                                {
                                    "id": 463,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/1_qg6pueA.png",
                                    "cropped": false
                                },
                                {
                                    "id": 354,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_tSWKZN8_Mxv0OdF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 280,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0404827050",
                            "field1": "Maliban",
                            "field2": "biscotti puff",
                            "field3": "al limone",
                            "field4": "30x200 g",
                            "description": "Maliban biscotti puff al limone 30x200 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "280",
                            "calcolo_prezzo": 1,
                            "price": "0.69",
                            "price_label": "\u20ac 0.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack",
                            "category_id": "346",
                            "subcategory_id": "386",
                            "blueprint": {
                                "top": 37.59466764969996,
                                "left": 33.442475603492554,
                                "width": 31.32383153569594,
                                "height": 10.382683844326026
                            },
                            "images": [
                                {
                                    "id": 355,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_El1L6M5_wLQJcFB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 281,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0177559646",
                            "field1": "Filipinos",
                            "field2": "biscotti",
                            "field3": "cioccolato fondente",
                            "field4": "12x135 g",
                            "description": "Filipinos biscotti cioccolato fondente 12x135 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "281",
                            "calcolo_prezzo": 1,
                            "price": "0.69",
                            "price_label": "\u20ac 0.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack",
                            "category_id": "346",
                            "subcategory_id": "386",
                            "blueprint": {
                                "top": 26.154193977770976,
                                "left": 65.02953261427837,
                                "width": 33.635079609655875,
                                "height": 21.932114408368616
                            },
                            "images": [
                                {
                                    "id": 356,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_W4EE0VO_FmNz1Ap.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 282,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6147889378",
                            "field1": "Biyori",
                            "field2": "riso per sushi",
                            "field3": "10 kg",
                            "field4": "",
                            "description": "Biyori riso per sushi 10 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "282",
                            "calcolo_prezzo": 1,
                            "price": "10.99",
                            "price_label": "\u20ac 10.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 48.163486184720064,
                                "left": 0.776836158192091,
                                "width": 25.930919363122754,
                                "height": 22.258985084709447
                            },
                            "images": [
                                {
                                    "id": 464,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_11_GxEGJsK_MzGIwNf.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 357,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_dHuhDEC_uEuxvcZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 283,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm4732861075",
                            "field1": "Biyori",
                            "field2": "anguilla grigliata",
                            "field3": "in salsa 10 pz",
                            "field4": "5 kg",
                            "description": "Biyori anguilla grigliata in salsa 10 pz 5 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "283",
                            "calcolo_prezzo": 1,
                            "price": "149.99",
                            "price_label": "\u20ac 149.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "category_id": "352",
                            "subcategory_id": "362",
                            "blueprint": {
                                "top": 48.599313753174506,
                                "left": 28.203646635850024,
                                "width": 24.852336928608118,
                                "height": 21.823157516255005
                            },
                            "images": [
                                {
                                    "id": 358,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_JvvSekp_wt0EOmT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 284,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm8173201799",
                            "field1": "Zenzero",
                            "field2": "sott'aceto",
                            "field3": "per sushi",
                            "field4": "10x1.5 kg",
                            "description": "Zenzero sott'aceto per sushi 10x1.5 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "284",
                            "calcolo_prezzo": 1,
                            "price": "2.89",
                            "price_label": "\u20ac 2.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 48.272443076833675,
                                "left": 53.165125834617356,
                                "width": 23.003338469440166,
                                "height": 21.169416163573352
                            },
                            "images": [
                                {
                                    "id": 465,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_12_1JvDaGi_qKo2PpX.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 359,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_HGvdw0v_wazmgOs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 285,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6392326411",
                            "field1": "Biyori",
                            "field2": "wasabi in polvere",
                            "field3": "10x1 kg",
                            "field4": "",
                            "description": "Biyori wasabi in polvere 10x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "285",
                            "calcolo_prezzo": 1,
                            "price": "5.49",
                            "price_label": "\u20ac 5.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 48.92618442951533,
                                "left": 75.60991268618386,
                                "width": 22.078839239856187,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 466,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_13_iBHPYsB_0ZOqlpR.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 360,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_1NB0sPJ_zIaVlz0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 286,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm0341873328",
                            "field1": "Biyori",
                            "field2": "sesamo bianco",
                            "field3": "tostato",
                            "field4": "12x1 kg",
                            "description": "Biyori sesamo bianco tostato 12x1 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "286",
                            "calcolo_prezzo": 1,
                            "price": "4.99",
                            "price_label": "\u20ac 4.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 69.95486460744192,
                                "left": 1.3931689779147414,
                                "width": 23.619671289162817,
                                "height": 20.842545487232524
                            },
                            "images": [
                                {
                                    "id": 467,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_14_qEmd7iK_GaAmZOJ.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 361,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_R25AxHr_EhslAM5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 287,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm9309511795",
                            "field1": "Yamasa",
                            "field2": "salsa di soia",
                            "field3": "dispenser",
                            "field4": "12x150 ml",
                            "description": "Yamasa salsa di soia dispenser 12x150 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "287",
                            "calcolo_prezzo": 1,
                            "price": "1.49",
                            "price_label": "\u20ac 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 70.17277839166915,
                                "left": 25.4301489470981,
                                "width": 23.003338469440166,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 420,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_2_LwDS3n7.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 362,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_14a4Pn0_cxC7QXZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "La salsa di soia \u00e8 prodotta solo con semi di soia, grano e sale. La proteina della soia si modifica nei composti che danno origine al sapore e al colore unici della salsa di soia durante l\u2019azione operata su di essa dai microrganismi.\nAllo stesso tempo il grano \u00e8 ricco di amido ed \u00e8 utilizzato principalmente per creare l\u2019aroma unico della salsa di soia. La salsa di soia deve essere soggetta a fermentazione fino a sei mesi. (Fonte: yamasa.eu)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/download_2.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 288,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3952276504",
                            "field1": "Tobikko giallo",
                            "field2": "",
                            "field3": "12x500 g",
                            "field4": "",
                            "description": "Tobikko giallo 12x500 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "288",
                            "calcolo_prezzo": 1,
                            "price": "14.99",
                            "price_label": "\u20ac 14.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 70.06382149955554,
                                "left": 49.00487930148947,
                                "width": 25.468669748330765,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 468,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_15_BOVkMgh_jxnMnAL.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 363,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_iBM11BN_OsmSiCr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 289,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6389668120",
                            "field1": "Tamanoi",
                            "field2": "aceto di grano",
                            "field3": "20x500 ml",
                            "field4": "",
                            "description": "Tamanoi aceto di grano 20x500 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "289",
                            "calcolo_prezzo": 1,
                            "price": "1.29",
                            "price_label": "\u20ac 1.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 70.06382149955554,
                                "left": 74.99357986646122,
                                "width": 22.387005649717512,
                                "height": 20.624631703005303
                            },
                            "images": [
                                {
                                    "id": 421,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_3_VYZ3N26.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 364,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_IpcRtpX_W13qv6x.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Tamanoi \u00e8 un grande produttore giapponese di aceto di riso. Questo aceto derivato dal grano \u00e8 utile e versatile in mille situazioni, da utilizzare come base pe la preparazione del sushi su o come semplice aceto adatto a mille usi. (Fonte: domechan.com)",
                                        "img": [
                                            "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/images\/download_3.jpg"
                                        ]
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_6_VKKiuhr.jpg",
                    "interactivities": [
                        {
                            "id": 290,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3517483931",
                            "field1": "P\/canton",
                            "field2": "Noodle beef mami\/",
                            "field3": "original\/ chili mami\/",
                            "field4": "chili lime\/ hot chili 72x60 g",
                            "description": "P\/canton Noodle beef mami\/ original\/ chili mami\/ chili lime\/ hot chili 72x60 g",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "290",
                            "calcolo_prezzo": 1,
                            "price": "0.00",
                            "price_label": "\u20ac 0",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "noodle beef mami",
                                "original",
                                "chili mami",
                                "chili lime",
                                "hot chili"
                            ],
                            "price_for_kg": "0.0000",
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "category_id": "346",
                            "subcategory_id": "358",
                            "blueprint": {
                                "top": 5.234470691957982,
                                "left": 2.163585002568054,
                                "width": 95.57652799178224,
                                "height": 82.07631885508097
                            },
                            "images": [
                                {
                                    "id": 469,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_R9WduPb_ObN93Jh.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 365,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_tzRnkaT_JWSmLZI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_7_yVp61ah.jpg",
                    "interactivities": [
                        {
                            "id": 315,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3866831958",
                            "field1": "Sant'anna",
                            "field2": "acqua naturale",
                            "field3": "frizzante",
                            "field4": "24x0.5 lt",
                            "description": "Sant'anna acqua naturale frizzante 24x0.5 lt",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "315",
                            "calcolo_prezzo": 1,
                            "price": "0.12",
                            "price_label": "\u20ac 0.12",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "category_id": "347",
                            "subcategory_id": "353",
                            "blueprint": {
                                "top": 5.0608887432094924,
                                "left": 1.694915254237288,
                                "width": 32.22650231124807,
                                "height": 22.03412983848049
                            },
                            "images": [
                                {
                                    "id": 470,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_1_BaRcRFk_eZ9vd3V.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 392,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_As44aa0_dlGoqSZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 316,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm7954386826",
                            "field1": "Coca cola",
                            "field2": "classica\/ zero",
                            "field3": "24x33 cl",
                            "field4": "",
                            "description": "Coca cola classica\/ zero 24x33 cl",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "316",
                            "calcolo_prezzo": 1,
                            "price": "0.49",
                            "price_label": "\u20ac 0.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classica",
                                "zero"
                            ],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "category_id": "347",
                            "subcategory_id": "355",
                            "blueprint": {
                                "top": 5.0608887432094924,
                                "left": 33.92141756548536,
                                "width": 32.64252696456085,
                                "height": 22.03412983848049
                            },
                            "images": [
                                {
                                    "id": 471,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_3_cPFengg_ePARLiH.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 472,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_2_35QVwq6_r065HEQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 393,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_V4feGJA_19kIbyJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "video_type": "video_file",
                                        "open_modal": false,
                                        "link": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/video\/Coca-Cola_presenta_Come_Mai_Prima_con_George_the_Poet.mp4"
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 317,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2925474306",
                            "field1": "Birra",
                            "field2": "moretti",
                            "field3": "in lattina",
                            "field4": "24x339 ml",
                            "description": "Birra moretti in lattina 24x339 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "317",
                            "calcolo_prezzo": 1,
                            "price": "0.47",
                            "price_label": "\u20ac 0.47",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "category_id": "347",
                            "subcategory_id": "356",
                            "blueprint": {
                                "top": 5.0608887432094924,
                                "left": 66.56394453004623,
                                "width": 30.839753466872118,
                                "height": 22.03412983848049
                            },
                            "images": [
                                {
                                    "id": 473,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_4_dZVvEgJ_azWH7p3.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 394,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_gqC901Z_ypBYVBk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 318,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1216639285",
                            "field1": "Fanta vap",
                            "field2": "",
                            "field3": "24x330 ml",
                            "field4": "",
                            "description": "Fanta vap 24x330 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "318",
                            "calcolo_prezzo": 1,
                            "price": "0.49",
                            "price_label": "\u20ac 0.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "category_id": "347",
                            "subcategory_id": "355",
                            "blueprint": {
                                "top": 27.095018581689985,
                                "left": 66.56394453004623,
                                "width": 30.839753466872118,
                                "height": 20.35010119371354
                            },
                            "images": [
                                {
                                    "id": 476,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_7_numGxFi_lPGsEUt.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 395,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_R9WPCET_zxNJBJe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "active": true,
                                    "title": "",
                                    "data": {
                                        "video_type": "video_file",
                                        "open_modal": false,
                                        "link": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/video\/Fanta_spot_Bounce_2011.mp4"
                                    }
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 319,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm4389562938",
                            "field1": "Dr pepper",
                            "field2": "classic",
                            "field3": "24x330 ml",
                            "field4": "",
                            "description": "Dr pepper classic 24x330 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "319",
                            "calcolo_prezzo": 1,
                            "price": "0.49",
                            "price_label": "\u20ac 0.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "category_id": "347",
                            "subcategory_id": "355",
                            "blueprint": {
                                "top": 27.095018581689985,
                                "left": 34.22958397534671,
                                "width": 32.33436055469952,
                                "height": 20.35010119371354
                            },
                            "images": [
                                {
                                    "id": 475,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_6_NWMbFtO_HSOfYoy.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 396,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_L4fUflV_Y57Tj0Q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 320,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2627000252",
                            "field1": "Birra",
                            "field2": "ichnusa",
                            "field3": "non filtrata in lattina",
                            "field4": "24x330 ml",
                            "description": "Birra ichnusa non filtrata in lattina 24x330 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "320",
                            "calcolo_prezzo": 1,
                            "price": "0.69",
                            "price_label": "\u20ac 0.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "category_id": "347",
                            "subcategory_id": "356",
                            "blueprint": {
                                "top": 27.095018581689985,
                                "left": 0,
                                "width": 34.22958397534671,
                                "height": 20.568014977940756
                            },
                            "images": [
                                {
                                    "id": 474,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/download_5_thlUsf9_0KFeuZx.jpg",
                                    "cropped": false
                                },
                                {
                                    "id": 397,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_sKM4IAw_Nky5OoZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 321,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2513529836",
                            "field1": "Aperol",
                            "field2": "",
                            "field3": "6x1 lt",
                            "field4": "",
                            "description": "Aperol 6x1 lt",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "321",
                            "calcolo_prezzo": 1,
                            "price": "8.99",
                            "price_label": "\u20ac 8.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "category_id": "347",
                            "subcategory_id": "363",
                            "blueprint": {
                                "top": 47.66303355963074,
                                "left": 2.6194144838212634,
                                "width": 31.610169491525447,
                                "height": 21.707259162139668
                            },
                            "images": [
                                {
                                    "id": 398,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_vo2Mv7s_AAE42Ot.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 322,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm9178662407",
                            "field1": "Campari",
                            "field2": "bitter",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "Campari bitter 1 lt",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "322",
                            "calcolo_prezzo": 1,
                            "price": "11.59",
                            "price_label": "\u20ac 11.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "category_id": "347",
                            "subcategory_id": "363",
                            "blueprint": {
                                "top": 47.66303355963074,
                                "left": 34.22958397534671,
                                "width": 35.87827426810474,
                                "height": 21.707259162139668
                            },
                            "images": [
                                {
                                    "id": 399,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_vKcZPlv_u905fwJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 323,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm8746993941",
                            "field1": "Vino",
                            "field2": "penque",
                            "field3": "rosso",
                            "field4": "75 cl",
                            "description": "Vino penque rosso 75 cl",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "323",
                            "calcolo_prezzo": 1,
                            "price": "1.39",
                            "price_label": "\u20ac 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "category_id": "347",
                            "subcategory_id": "371",
                            "blueprint": {
                                "top": 47.66303355963074,
                                "left": 70.10785824345146,
                                "width": 27.426810477657934,
                                "height": 21.707259162139668
                            },
                            "images": [
                                {
                                    "id": 400,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_bmjxbzl_4QeVDiA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 324,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm8011607345",
                            "field1": "Merum 11%",
                            "field2": "prosecco",
                            "field3": "6x75 ml",
                            "field4": "",
                            "description": "Merum 11% prosecco 6x75 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "324",
                            "calcolo_prezzo": 1,
                            "price": "2.99",
                            "price_label": "\u20ac 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "category_id": "347",
                            "subcategory_id": "371",
                            "blueprint": {
                                "top": 69.3702927217704,
                                "left": 70.10785824345146,
                                "width": 27.426810477657934,
                                "height": 21.766540791190458
                            },
                            "images": [
                                {
                                    "id": 401,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_cLQyQIT_EuHJ62m.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 325,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6021037028",
                            "field1": "B-zen",
                            "field2": "liquore zenzero 30%",
                            "field3": "6x700 ml",
                            "field4": "",
                            "description": "B-zen liquore zenzero 30% 6x700 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "325",
                            "calcolo_prezzo": 1,
                            "price": "6.29",
                            "price_label": "\u20ac 6.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "category_id": "347",
                            "subcategory_id": "363",
                            "blueprint": {
                                "top": 69.3702927217704,
                                "left": 36.97996918335901,
                                "width": 33.12788906009244,
                                "height": 21.766540791190458
                            },
                            "images": [
                                {
                                    "id": 402,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_XMmxWdk_iWWNC8B.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 326,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3071445800",
                            "field1": "Taihua",
                            "field2": "grappa",
                            "field3": "ginseng",
                            "field4": "12x700 ml",
                            "description": "Taihua grappa ginseng 12x700 ml",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "326",
                            "calcolo_prezzo": 1,
                            "price": "6.49",
                            "price_label": "\u20ac 6.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "category_id": "347",
                            "subcategory_id": "363",
                            "blueprint": {
                                "top": 69.3702927217704,
                                "left": 2.003081664098604,
                                "width": 34.9768875192604,
                                "height": 21.766540791190458
                            },
                            "images": [
                                {
                                    "id": 403,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_TEQaSbT_fNaLmbH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/page_8_dS8VFUZ.jpg",
                    "interactivities": [
                        {
                            "id": 327,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6365787720",
                            "field1": "Energy",
                            "field2": "detergente",
                            "field3": "lavastoviglie",
                            "field4": "12 kg",
                            "description": "Energy detergente lavastoviglie 12 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "327",
                            "calcolo_prezzo": 1,
                            "price": "7.99",
                            "price_label": "\u20ac 7.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene casa",
                            "subcategory": "Piatti",
                            "category_id": "398",
                            "subcategory_id": "399",
                            "blueprint": {
                                "top": 1.247397519233165,
                                "left": 0,
                                "width": 32.534668721109405,
                                "height": 21.92517294636688
                            },
                            "images": [
                                {
                                    "id": 404,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_FPw0EmX_Vz72SUx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 328,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5909373098",
                            "field1": "Energy",
                            "field2": "brillante",
                            "field3": "2x5 kg",
                            "field4": "",
                            "description": "Energy brillante 2x5 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "328",
                            "calcolo_prezzo": 1,
                            "price": "5.99",
                            "price_label": "\u20ac 5.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene casa",
                            "subcategory": "Piatti",
                            "category_id": "398",
                            "subcategory_id": "399",
                            "blueprint": {
                                "top": 1.029483735005946,
                                "left": 32.84283513097073,
                                "width": 32.38058551617874,
                                "height": 21.81621605425327
                            },
                            "images": [
                                {
                                    "id": 405,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_MIu918K_rh7Eogp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 329,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2180405295",
                            "field1": "Energy",
                            "field2": "detergente",
                            "field3": "piatti",
                            "field4": "10 kg",
                            "description": "Energy detergente piatti 10 kg",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "329",
                            "calcolo_prezzo": 1,
                            "price": "4.99",
                            "price_label": "\u20ac 4.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene casa",
                            "subcategory": "Piatti",
                            "category_id": "398",
                            "subcategory_id": "399",
                            "blueprint": {
                                "top": 1.029483735005946,
                                "left": 65.22342064714945,
                                "width": 33.38983050847456,
                                "height": 21.81621605425327
                            },
                            "images": [
                                {
                                    "id": 406,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_e0loKLL_USe7vvk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 330,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm7514478910",
                            "field1": "Bis bioplastica",
                            "field2": "",
                            "field3": "500 pz",
                            "field4": "",
                            "description": "Bis bioplastica 500 pz",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "330",
                            "calcolo_prezzo": 1,
                            "price": "0.13",
                            "price_label": "\u20ac 0.13",
                            "equivalence": 1,
                            "quantity_step": 500,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene casa",
                            "subcategory": "Extra",
                            "category_id": "398",
                            "subcategory_id": "402",
                            "blueprint": {
                                "top": 22.845699789259214,
                                "left": 65.22342064714945,
                                "width": 33.38983050847456,
                                "height": 21.657583899076855
                            },
                            "images": [
                                {
                                    "id": 407,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_0I8mA8l_FreEAGd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 331,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm5854702326",
                            "field1": "Shopper bio",
                            "field2": "mb light",
                            "field3": "27+6+66x50",
                            "field4": "500\/ct",
                            "description": "Shopper bio mb light 27+6+66x50 500\/ct",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "331",
                            "calcolo_prezzo": 1,
                            "price": "18.99",
                            "price_label": "\u20ac 18.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene casa",
                            "subcategory": "Extra",
                            "category_id": "398",
                            "subcategory_id": "402",
                            "blueprint": {
                                "top": 22.276077697159767,
                                "left": 32.84283513097073,
                                "width": 32.38058551617874,
                                "height": 22.36100051482132
                            },
                            "images": [
                                {
                                    "id": 408,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_aun0aKQ_gHFA37k.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 332,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm1075451970",
                            "field1": "Daily gold",
                            "field2": "bobina food",
                            "field3": "contact",
                            "field4": "",
                            "description": "Daily gold bobina food contact",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "332",
                            "calcolo_prezzo": 1,
                            "price": "3.99",
                            "price_label": "\u20ac 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e cucina",
                            "subcategory": "Altro",
                            "category_id": "403",
                            "subcategory_id": "404",
                            "blueprint": {
                                "top": 22.82086215772781,
                                "left": 0.662557781201855,
                                "width": 32.18027734976887,
                                "height": 21.81621605425327
                            },
                            "images": [
                                {
                                    "id": 409,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_0IKU1ra_ZI3LLej.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 333,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm6025132986",
                            "field1": "Alzata",
                            "field2": "per temaki",
                            "field3": "in plastica",
                            "field4": "",
                            "description": "Alzata per temaki in plastica",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "333",
                            "calcolo_prezzo": 1,
                            "price": "3.89",
                            "price_label": "\u20ac 3.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e cucina",
                            "subcategory": "Altro",
                            "category_id": "403",
                            "subcategory_id": "404",
                            "blueprint": {
                                "top": 44.63707821198109,
                                "left": 0.662557781201855,
                                "width": 32.18027734976887,
                                "height": 21.875497683304076
                            },
                            "images": [
                                {
                                    "id": 410,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_Hz7FZxJ_9hwR6bE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 334,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm2219318507",
                            "field1": "Tea pot",
                            "field2": "handpainted",
                            "field3": "16 pcs",
                            "field4": "",
                            "description": "Tea pot handpainted 16 pcs",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "334",
                            "calcolo_prezzo": 1,
                            "price": "7.99",
                            "price_label": "\u20ac 7.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e cucina",
                            "subcategory": "Altro",
                            "category_id": "403",
                            "subcategory_id": "404",
                            "blueprint": {
                                "top": 44.63707821198109,
                                "left": 32.84283513097073,
                                "width": 33.613251155624035,
                                "height": 21.875497683304076
                            },
                            "images": [
                                {
                                    "id": 411,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_QHvgcuw_W8YKAkh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 335,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm9320589991",
                            "field1": "Frullino",
                            "field2": "per te",
                            "field3": "matcha",
                            "field4": "",
                            "description": "Frullino per te matcha",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "335",
                            "calcolo_prezzo": 1,
                            "price": "4.19",
                            "price_label": "\u20ac 4.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e cucina",
                            "subcategory": "Altro",
                            "category_id": "403",
                            "subcategory_id": "404",
                            "blueprint": {
                                "top": 44.63707821198109,
                                "left": 66.45608628659477,
                                "width": 32.4884437596302,
                                "height": 21.875497683304076
                            },
                            "images": [
                                {
                                    "id": 412,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_LA8wAJ5_8FM327T.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        },
                        {
                            "id": 336,
                            "ean_code": null,
                            "codice_interno_insegna": "cstm3838440150",
                            "field1": "Tea set",
                            "field2": "18 pz",
                            "field3": "",
                            "field4": "",
                            "description": "Tea set 18 pz",
                            "grammage": 0,
                            "offer_price": null,
                            "type": "product",
                            "item_id": "336",
                            "calcolo_prezzo": 1,
                            "price": "15.99",
                            "price_label": "\u20ac 15.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 1,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e cucina",
                            "subcategory": "Altro",
                            "category_id": "403",
                            "subcategory_id": "404",
                            "blueprint": {
                                "top": 66.51257589528515,
                                "left": 0,
                                "width": 98.94453004622495,
                                "height": 21.925172946366878
                            },
                            "images": [
                                {
                                    "id": 413,
                                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/products\/cropped_image_8bRUywC_dfL8h8I.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "active": true,
                                    "title": null,
                                    "data": ""
                                }
                            ],
                            "weight_unit_of_measure": "gr"
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "https:\/\/interattivo-dev.s3.amazonaws.com\/Ck5wM5cflh\/pages\/FINE-UNICASH_7nQtTdI.png",
                    "interactivities": []
                }
            ]
        }
    }
}

const lefletSwitch = () => {
    return leaflets.client;
}

export const serverResponse = lefletSwitch();