import React, { Fragment } from "react";
import { sidebarData } from "util/data/sidebar";
import ShareFlyer from '../share/ShareFlyer';
import IntlMessages from "util/intlMessages";
import NavList from "components/sidebar/NavList";
import { serverResponse } from "../../util/fakeServer";

const BottomSidebar = props => {
    let listNameStyle;
    let sidebar;
    let appNameStyle = {};

    const {
        mini,
        drawerWidth,
        miniDrawerWidth,
        onMouseEnter,
        onMouseLeave,
        sidebarTheme,
        closeDrawer,
        themeSetting
    } = props;
    return (
        <div id="bottomSideBar">
            <ul className="nav">
                {sidebarData.map((list, i) => {
                    return (
                        <Fragment key={i}>
                            {list.type &&
                                list.type === "heading" ? (
                                    <div className="sidelist-header-name">
                                        {
                                            <IntlMessages
                                                id={list.name}
                                            />
                                        }
                                    </div>
                                ) : (
                                    <NavList
                                        list={list}
                                        {...props}
                                        forceDrawerMini={props.forceDrawerMini}
                                    />
                                )}
                        </Fragment>
                    );
                })}
                {serverResponse.config.sidebar.includes('share') && <ShareFlyer forceDrawerMini={props.forceDrawerMini} />}
            </ul>
        </div>
    )
}

export default BottomSidebar;