import React, { Component, createRef } from 'react';
import Product from '../components/productsList/Product';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import {
        InputGroup,
        InputGroupAddon,
        Input,
        Button,
        CustomInput,
        Label,
        Row,
        Col,
        Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
        faSearch,
        faBars,
        faStream,
        faArrowCircleUp
} from '@fortawesome/free-solid-svg-icons';
import '../assets/css/custom.css';
import InfiniteScroll from 'react-infinite-scroller';
import { serverResponse } from '../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../settings/gaConfig';
import { isIE, isEdge, isMobile, isIOS } from 'react-device-detect';

import Modal from 'react-modal';
import ReactPlayer from 'react-player'

if (serverResponse.config.only_browsable || serverResponse.config.hide_product_page) {
        var path = window.location.pathname;
        if (path == '/categories') {
                window.location.href = '/leaflet'
        }
}

let videoModalStyles = {
        content: {
                background: "transparent",
                border: 0,
        },
        overlay: {
                zIndex: 999999999
        }
};

if (isMobile) {
        videoModalStyles = {
                content: {
                        background: "transparent",
                        border: 0,
                        padding: 0,
                        top: "10px",
                        left: "10px",
                        bottom: "10px",
                        right: "10px",
                },
                overlay: {
                        zIndex: 999999999
                }
        };
}

const openBanner = () => {
        if (serverResponse.config.internal_banner_click) {
                var id_prod_special = serverResponse.config.product_banner;
                if (document.getElementById("plus-" + id_prod_special)) {
                        ReactGA.event({
                                category: serverResponse.config.release_id,
                                action: gaEventLabel.banner_click,
                                label: String(id_prod_special)
                        });
                        document.getElementById("plus-" + id_prod_special).click();
                }
        }
        else if (serverResponse.config.external_banner_click) {
                window.open(serverResponse.config.href_banner, "_blank");
        }
}

class Categories extends Component {

        constructor(props) {
                super(props);

                let page_has_video = false;
                let video_only_once = false;
                let video_mandatory_seconds = 0;
                let videos = [];
                let video_never = false;

                if (serverResponse.hasOwnProperty('video_categories')) {
                        page_has_video = true;
                        videos = serverResponse.video_categories.videos.map(i => i.page);
                        video_only_once = serverResponse.video_categories.show_only_once;
                        video_mandatory_seconds = serverResponse.video_categories.mandatory_seconds;
                }


                const cookies = new Cookies();
                if (cookies.get('neverTutorial') !== undefined)
                        video_never = true;
                this.state = {
                        products: [],
                        nameFilter: "",
                        brandFilter: false,
                        underpriceFilter: false,
                        categoryFilter: "",
                        subCategoryFilter: "",
                        items: 10,
                        hasMoreItems: true,
                        showScrollUp: false,
                        page_has_video: page_has_video,
                        videos: videos,
                        video_only_once: video_only_once,
                        show_close_video: false,
                        preroll_video_in_progress: false,
                        video_only_once: video_only_once,
                        video_page_number: null,
                        video_page_item_id: null,
                        countdown: video_mandatory_seconds,
                        video_mandatory_seconds: video_mandatory_seconds,
                        video_modal_open: false,
                        video_url: "",
                        video_muted: true,
                        video_playing: false,
                        video_never: video_never,
                }

                this.closeVideoModal = this.closeVideoModal.bind(this);
                this.onVideoProgress = this.onVideoProgress.bind(this);
                this.onVideoStart = this.onVideoStart.bind(this);
                this.toggleVideoMuted = this.toggleVideoMuted.bind(this);
                this.toggleVideoNever = this.toggleVideoNever.bind(this);
                this.showPageVideo = this.showPageVideo.bind(this);
                this.onVideoEnded = this.onVideoEnded.bind(this);
                this.userCloseVideo = this.userCloseVideo.bind(this);
        }

        ref = player => {
                this.player = player
        }

        toggleVideoMuted() {
                this.setState({ video_muted: !this.state.video_muted })
        }

        toggleVideoNever() {
                this.closeVideoModal();
                this.setState({ video_never: !this.state.video_never })
                const cookies = new Cookies();
                cookies.set('neverTutorial', 1, { path: '/' });
        }

        onVideoEnded() {
                // commented in debug mode
                //this.closeVideoModal();
                if (serverResponse.config.ga_active) {
                        /*    ReactGA.event({
                                    category: serverResponse.config.release_id,
                                    action: gaEventLabel.page_video_completion,
                                    label: String(this.state.video_page_item_id)
                            }); */
                }
        }

        userCloseVideo() {
                this.closeVideoModal();
                if (serverResponse.config.ga_active) {
                        /*       ReactGA.event({
                                       category: serverResponse.config.release_id,
                                       action: gaEventLabel.page_video_closed_by_user,
                                       label: String(this.state.video_page_item_id)
                               }); */
                }
        }

        closeVideoModal() {
                this.setState({
                        video_modal_open: false,
                        video_url: "",
                        preroll_video_in_progress: false,
                        show_close_video: false,
                });
        }

        showPageVideo(page_number, onload = false) {
                if (this.state.video_only_once) {
                        let session_video_pages = sessionStorage.getItem('video_pages');
                        if (session_video_pages == null) {
                                sessionStorage.setItem('video_pages', JSON.stringify([page_number]));
                        } else {
                                let session_video_pages_parsed = JSON.parse(session_video_pages);
                                if (session_video_pages_parsed.includes(page_number)) {
                                        return
                                } else {
                                        session_video_pages_parsed.push(page_number);
                                        sessionStorage.setItem('video_pages', JSON.stringify(session_video_pages_parsed));
                                }
                        }
                }

                let videoUrl = serverResponse.video_categories.videos.filter(i => i.page == page_number)[0].url;
                if (isMobile) {
                        videoUrl = serverResponse.video_categories.videos.filter(i => i.page == page_number)[0].url_mobile;
                }


                this.setState({
                        video_modal_open: true,
                        //   video_url: process.env.PUBLIC_URL + serverResponse.video_categories.videos.filter(i => i.page == page_number)[0].url,
                        video_url: videoUrl,
                        video_playing: true,
                        video_muted: (onload ? true : isIOS),
                        countdown: this.state.video_mandatory_seconds
                },
                        () => {
                                /*       if (serverResponse.config.ga_active) {
                                               ReactGA.event({
                                                       category: serverResponse.config.release_id,
                                                       action: gaEventLabel.page_video_play,
                                                       label: String(this.state.video_page_item_id)
                                               });
                                       } */
                        });
        }

        onVideoProgress(args) {
                // { played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 }
                if (this.state.preroll_video_in_progress) {
                        this.setState({
                                // countdown: parseInt(this.state.countdown - parseInt(args.playedSeconds))
                                countdown: parseInt(this.state.countdown - 1)
                        });
                        if (args.playedSeconds > this.state.video_mandatory_seconds) {
                                this.setState({
                                        preroll_video_in_progress: false,
                                        show_close_video: true
                                });
                                /*     if (serverResponse.config.ga_active) {
                                             ReactGA.event({
                                                     category: serverResponse.config.release_id,
                                                     action: gaEventLabel.page_video_mandatory_completed,
                                                     label: String(this.state.video_page_item_id)
                                             });
                                     } */
                        }
                }
        }

        onVideoStart() {
                // console.log("video start", Date.now());
                this.setState({
                        preroll_video_in_progress: true
                });
        }

        handleChangeName = (e) => {
                this.setState({
                        nameFilter: e.target.value,
                        items: 10,
                        hasMoreItems: true,
                });
                document.getElementById('scrollable-div').scrollTop = 0;
        }

        handleChangeBrand = (e) => {
                this.setState({
                        brandFilter: e.target.checked,
                        items: 10,
                        hasMoreItems: true,
                });
                document.getElementById('scrollable-div').scrollTop = 0;
        }

        handleChangeUnderprice = (e) => {
                this.setState({
                        underpriceFilter: e.target.checked,
                        items: 10,
                        hasMoreItems: true,
                });
                document.getElementById('scrollable-div').scrollTop = 0;
        }

        handleChangeCategory = (e) => {
                this.setState({
                        categoryFilter: e.target.value,
                        subCategoryFilter: "",
                        items: 10,
                        hasMoreItems: true,
                });
                if (isMobile) {
                        document.getElementById('scrollable-div').scrollTop = 50;
                }
        }

        handleChangeSubCategory = (e) => {
                this.setState({
                        subCategoryFilter: e.target.value,
                        items: 10,
                        hasMoreItems: true,
                });
                if (isMobile) {
                        document.getElementById('scrollable-div').scrollTop = 100;
                }
        }

        productFilter = (product) => {

                let brandCheck = (this.state.brandFilter)
                        ? (product.pam) ? true : false
                        : true;
                let underpriceCheck = (this.state.underpriceFilter)
                        ? (product.underpriced_product) ? true : false
                        : true;
                let nameCheck = product.description.toLowerCase().includes(this.state.nameFilter.toLowerCase()) ? true : false;
                let categoryCheck = (this.state.categoryFilter !== "")
                        ? product.category.toLowerCase() === this.state.categoryFilter.toLowerCase() ? true : false
                        : true
                let subcategoryCheck = (this.state.subCategoryFilter !== "")
                        ? product.subcategory.toLowerCase() === this.state.subCategoryFilter.toLowerCase() ? true : false
                        : true

                return brandCheck && nameCheck && underpriceCheck && categoryCheck && subcategoryCheck;
        }

        resetFilters = () => {
                this.setState({
                        nameFilter: "",
                        brandFilter: false,
                        underpriceFilter: false,
                        categoryFilter: "",
                        subCategoryFilter: "",
                        items: 10,
                        hasMoreItems: true,
                });
                document.getElementById('scrollable-div').scrollTop = 0;
        }

        fetchMoreData = () => {
                const {
                        products,
                        currentProducts,
                        numProducts,
                } = this.state;
                if (currentProducts.length >= products.length) {
                        this.setState({ hasMore: false });
                        return;
                }

                var tmpProducts = products.filter(this.productFilter);
                this.setState({
                        currentProducts: tmpProducts.slice(0, numProducts),
                        numProducts: numProducts + 10,
                })
        }


        //Infinite Loop      
        showItems = () => {
                var currentProducts = this.state.products.filter(i => i.markers.filter(m => m.type == "plus").length > 0).filter(this.productFilter);
                currentProducts = currentProducts.slice(0, this.state.items);
                const items = currentProducts.map((element, key) => {
                        return (!element.duplicate && element.item_id != "cstm33194053" &&
                                <div key={key} className="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                                        <Product info={element} />
                                </div>
                        );
                })
                return items;
        }

        loadMore = () => {
                if (this.state.items > this.state.products.length) {
                        this.setState({ hasMoreItems: false });
                } else {
                        this.setState({ items: this.state.items + 10 });
                }
        }

        componentWillMount() {
                let tmpProducts = [];
                let tmp = Object.assign({}, serverResponse);
                tmp.leaflet.pages.forEach((page) => {
                        tmpProducts = tmpProducts.concat(page.interactivities);
                });
                tmpProducts.sort((a, b) => {
                        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                        if (aID === - 1) return + 1
                        if (bID === - 1) return - 1
                        return aID - bID
                });
                this.setState({
                        products: tmpProducts,
                });
                if (this.props.match.params.item_id !== undefined) {
                        this.setState({ items: tmpProducts.length });
                }
        }

        componentDidMount() {
                if (this.props.match.params.name !== undefined) {
                        this.setState({ nameFilter: this.props.match.params.name });
                }

                if (serverResponse.config.ga_active) {
                        ReactGA.initialize(serverResponse.config.ga_tracking_id, {
                                gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
                        })
                        ReactGA.pageview("/" + serverResponse.config.release_id + "/categorie");
                }

                if (this.state.page_has_video && !this.state.video_never) {
                        this.showPageVideo(-1, true);
                }
                if (this.props.match.params.item_id !== undefined) {
                        if (document.getElementById("plus-" + this.props.match.params.item_id))
                                document.getElementById("plus-" + this.props.match.params.item_id).click();
                }
        }

        render() {

                const {
                        nameFilter,
                        brandFilter,
                        underpriceFilter,
                        categoryFilter,
                        subCategoryFilter,
                        hasMoreItems,
                        showScrollUp
                } = this.state;
                let subcategories = [];
                if (this.state.categoryFilter !== "") {
                        serverResponse.leaflet.categories.map(element => {
                                if (element.category_name === this.state.categoryFilter) {
                                        subcategories = element.subcategories;
                                }
                                return false;
                        })
                }
                /*<CustomInput type="checkbox" id="branded-products" label="Prodotti a marchio" checked={brandFilter} onChange={this.handleChangeBrand} />
                 <CustomInput type="checkbox" id="underprice-products" label="Carta arancio" checked={underpriceFilter} onChange={this.handleChangeUnderprice} />
                 
                 */
                let brandInput = "";
                let underpriceInput = "";
                let amostOneCustomCheckbox = false;
                if (Boolean(serverResponse.config.filterBrand) === true) {
                        amostOneCustomCheckbox = true;
                        brandInput = <CustomInput type="checkbox" id="branded-products" label="Prodotti a marchio" checked={brandFilter} onChange={this.handleChangeBrand} />
                }
                if (Boolean(serverResponse.config.filterUnderprice) === true) {
                        amostOneCustomCheckbox = true;
                        underpriceInput = <CustomInput type="checkbox" id="underprice-products" label="Prodotti in offerta" checked={underpriceFilter} onChange={this.handleChangeUnderprice} />
                }

                const scrollableClass = {
                        height: "78vh",
                        width: "100%",
                        overflow: "auto"
                }

                const scrollableClassMobile = {
                        height: 'calc(100vh - 66px)',
                        width: "100%",
                        overflow: "auto"
                }

                const filtri = <div className="row ma-0">
                        <div className="col-sm-12" style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "15px" }}>
                                <div className="roe-card-style">
                                        {/* <div className="roe-card-header">
                                         <h3>Filtri</h3> 
                                </div>*/}
                                        <div className="roe-card-body" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                                                <Row>
                                                        <Col md="4" className="margin-mobile">
                                                                {/*   <Label for="product">Cosa cerchi?</Label> */}
                                                                <InputGroup>
                                                                        <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faSearch} /></span></InputGroupAddon>
                                                                        <Input placeholder="Prodotto" id="product" onChange={this.handleChangeName} value={nameFilter} />
                                                                </InputGroup>
                                                        </Col>
                                                        <Col md="4" className="margin-mobile">
                                                                {/*   <Label for="category">Seleziona categoria</Label>*/}
                                                                <InputGroup>
                                                                        <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>
                                                                        <Input type="select" name="select" id="category" value={categoryFilter} onChange={this.handleChangeCategory}>
                                                                                <option value="">Scegli una categoria</option>

                                                                                {
                                                                                        /*serverResponse.leaflet.category.map((element, key) => {
                                                                                         return <option key={key} value={element}>{element}</option>
                                                                                         })*/
                                                                                        serverResponse.leaflet.categories.map((element, key) => {
                                                                                                return <option key={key} value={element.category_name}>{element.category_name}</option>
                                                                                        })
                                                                                }
                                                                        </Input>
                                                                </InputGroup>
                                                        </Col>

                                                        <Col md="4" className="margin-mobile">
                                                                {/*  <Label for="subcategory">Seleziona sottocategoria</Label> */}
                                                                <InputGroup>
                                                                        <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faStream} /></span></InputGroupAddon>
                                                                        <Input type="select" name="select" id="subcategory" value={subCategoryFilter} onChange={this.handleChangeSubCategory}>
                                                                                <option value="">Scegli una sottocategoria</option>
                                                                                {
                                                                                        subcategories.map((element, key) => {
                                                                                                return <option key={key} value={element}>{element}</option>
                                                                                        })
                                                                                }
                                                                        </Input>
                                                                        <Button className="d-none d-sm-block" style={{ marginLeft: "20px" }} color="primary" onClick={() => this.resetFilters()}>Reset</Button>
                                                                </InputGroup>
                                                        </Col>

                                                        <Col md="4" className="d-block d-md-none">
                                                                <InputGroup>
                                                                        <Button style={{ marginLeft: "auto" }} color="primary" onClick={() => this.resetFilters()}>Reset</Button>
                                                                </InputGroup>
                                                        </Col>
                                                </Row>
                                                {amostOneCustomCheckbox &&
                                                        <Row style={{ marginTop: "10px" }}>
                                                                <Col md="12">
                                                                        {brandInput}
                                                                        {underpriceInput}
                                                                </Col>
                                                        </Row>
                                                }
                                        </div>
                                </div>
                        </div>
                </div>

                return (
                        <div>
                                <input type="button" id="prerollButton" value="aaaa" style={{ display: 'none' }}
                                        onClick={(e) => {
                                                this.showPageVideo(-1);
                                        }}
                                />
                                {!isMobile && filtri}

                                <div id="scrollable-div" style={isMobile ? scrollableClassMobile : scrollableClass}
                                        onScroll={(e) => {
                                                if (e.target.scrollTop > 300) {
                                                        if (!this.state.showScrollUp) {
                                                                this.setState({
                                                                        showScrollUp: true
                                                                })
                                                        }
                                                } else {
                                                        if (this.state.showScrollUp) {
                                                                this.setState({
                                                                        showScrollUp: false
                                                                })
                                                        }
                                                }
                                        }}
                                >
                                        {isMobile && filtri}
                                        {!isMobile && serverResponse.config.category_banner &&
                                                <div className="col-sm-12 text-center">
                                                        <img onClick={openBanner} src={serverResponse.config.category_banner} style={{ cursor: 'pointer', maxWidth: '100%' }} />
                                                </div>
                                        }
                                        {isMobile && serverResponse.config.category_banner_mobile &&
                                                <div className="col-sm-12">
                                                        <img onClick={openBanner} src={serverResponse.config.category_banner_mobile} style={{ cursor: 'pointer', maxWidth: '100%' }} />
                                                </div>
                                        }
                                        <InfiniteScroll
                                                id="scroller"
                                                loadMore={this.loadMore}
                                                hasMore={hasMoreItems}
                                                loader={
                                                        <div key="loader" className="col-sm-12 col-md-12 col-lg-12 ptb-15">
                                                                <Alert className="c-light">Loading...</Alert>
                                                        </div>
                                                }
                                                useWindow={false}
                                                className="row ma-0 filtered-products"
                                        >

                                                {this.showItems()}

                                        </InfiniteScroll>
                                </div>
                                <FontAwesomeIcon
                                        size="3x"
                                        className="scroll-up-btn"
                                        style={showScrollUp ? { display: "block" } : { display: "none" }}
                                        icon={faArrowCircleUp}
                                        onClick={() => {
                                                if (isIE || isEdge) {
                                                        document.getElementById('scrollable-div').scrollTop = 0;
                                                } else {
                                                        document.getElementById('scrollable-div').scroll({
                                                                top: 0,
                                                                left: 0,
                                                                behavior: 'smooth'
                                                        });
                                                }
                                        }}
                                />
                                <Modal
                                        isOpen={this.state.video_modal_open}
                                        style={videoModalStyles}
                                        ariaHideApp={false}
                                >
                                        <div className="modal-page-video-container">
                                                {
                                                        !this.state.video_never &&
                                                        <button onClick={this.toggleVideoNever} className="audio-page-video-button-top-left">
                                                                <i className="fas fa-times" />
                                                                <span style={{ paddingLeft: "10px" }}>Non mostrare più</span>
                                                        </button>
                                                }
                                                {
                                                        this.state.video_muted &&
                                                        <button onClick={this.toggleVideoMuted} className="audio-page-video-button-bottom-left">
                                                                <i className="fas fa-volume-mute" />
                                                                <span style={{ paddingLeft: "10px" }}>Attiva audio</span>
                                                        </button>
                                                }
                                                {
                                                        this.state.show_close_video &&
                                                        <button onClick={this.userCloseVideo} className="close-page-video-button">
                                                                <i className="fas fa-times" />
                                                        </button>
                                                }
                                                {
                                                        (!this.state.show_close_video) && (this.state.countdown > 0) &&
                                                        <button className="wait-page-video-button">
                                                                Sfoglia il volantino tra {this.state.countdown} secondi
                                                        </button>
                                                }


                                                <ReactPlayer
                                                        className='react-page-player'
                                                        url={this.state.video_url}
                                                        playing={this.state.video_playing}
                                                        width='100%'
                                                        height='100%'
                                                        onProgress={this.onVideoProgress}
                                                        onStart={this.onVideoStart}
                                                        onEnded={this.onVideoEnded}
                                                        muted={this.state.video_muted}
                                                        playsinline={isMobile}
                                                        ref={this.ref}
                                                        volume={1}
                                                />
                                        </div>

                                </Modal>
                        </div>
                );
        }
}

const mapStateToProps = (state) => {
        return {
                ...state.themeChanger
        };
}

export default connect(mapStateToProps, null)(Categories);
export function reloadTutorial() {
        if (document.getElementById('prerollButton'))
                document.getElementById('prerollButton').click();
};